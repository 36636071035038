/*
1. Prevent padding and border from affecting element width. (https://github.com/mozdevs/cssremedy/issues/4)
2. Allow adding a border to an element by just adding a border-width. (https://github.com/tailwindcss/tailwindcss/pull/116)
*/
:root {
  /* Primary Colors */
  --primary-color: rgb(2, 191, 97); /* Mint Green */
  --primary-color-light: rgb(128, 237, 179); /* Lighter Mint Green */
  --primary-color-dark: #00ab6c; /* Darker Mint Green */
  --primary-color-rgb: 2 191 97;

  /* Secondary Colors */
  --secondary-color: rgb(0, 51, 102); /* Navy Blue */
  --secondary-color-light: rgb(51, 102, 153); /* Lighter Navy Blue */
  --secondary-color-dark: rgb(0, 41, 82); /* Darker Navy Blue */
  --secondary-color-rgb: 0 51 102;

  /* Accent Colors */
  --accent-color: rgb(255, 99, 71); /* Coral */
  --accent-color-light: rgb(255, 140, 105); /* Lighter Coral */
  --accent-color-dark: rgb(204, 79, 56); /* Darker Coral */
  --accent-color-rgb: 255 99 71;

  /* Success Colors */
  --success-color: rgb(76, 175, 80); /* Success Green */
  --success-color-light: rgb(129, 199, 132); /* Lighter Success Green */
  --success-color-dark: rgb(56, 142, 60); /* Darker Success Green */
  --success-color-rgb: 76 175 80;

  /* Warning Colors */
  --warning-color: rgb(255, 193, 7); /* Yellow Warning */
  --warning-color-light: rgb(255, 218, 94); /* Lighter Warning Yellow */
  --warning-color-dark: rgb(204, 153, 0); /* Darker Warning Yellow */
  --warning-color-rgb: 255, 193, 7;

  /* Background Colors */
  --background-color: rgb(248, 249, 250); /* Light Grey Background */
  --background-color-dark: rgb(33, 37, 41); /* Dark Mode Background */

  /* Text Colors */
  --text-color: rgb(33, 37, 41); /* Dark Grey for standard text */
  --text-color-light: rgb(255, 255, 255); /* White text for dark backgrounds */
  --text-color-muted: rgb(108, 117, 125); /* Muted Grey for secondary text */

  /* Border Colors */
  --border-color: rgb(206, 212, 218); /* Light grey for borders */
  --border-color-dark: rgb(173, 181, 189); /* Darker grey border for emphasis */

  /* Button Colors */
  --button-primary-bg: var(--primary-color);
  --button-primary-hover-bg: var(--primary-color-dark);
  --button-secondary-bg: var(--secondary-color);
  --button-secondary-hover-bg: var(--secondary-color-dark);

  /* Shadow Colors */
  --shadow-color: rgba(0, 0, 0, 0.15); /* Soft shadow */
  --shadow-color-dark: rgba(0, 0, 0, 0.3); /* Stronger shadow */

  /* Hover Effects */
  --primary-hover-color: var(--primary-color-dark);
  --secondary-hover-color: var(--secondary-color-dark);
  --accent-hover-color: var(--accent-color-dark);

  /* Background */
  --bg-primary-color-lighter: #DDFFEE; /* Lighter Mint Green */
  --bg-primary-color-light: rgb(230, 255, 241); /* Lighter Mint Green */

  /* Gradient Colors */
  --primary-gradient: linear-gradient(45deg, var(--primary-color-light), var(--primary-color-dark));
  --secondary-gradient: linear-gradient(45deg, var(--secondary-color-light), var(--secondary-color-dark));
  --accent-gradient: linear-gradient(45deg, var(--accent-color-light), var(--accent-color-dark));
}





*,
::before,
::after {
  box-sizing: border-box;
  /* 1 */
  border-width: 0;
  /* 2 */
  border-style: solid;
  /* 2 */
  border-color: #e5e7eb;
  /* 2 */
}

::before,
::after {
  --tw-content: '';
}

/*
1. Use a consistent sensible line-height in all browsers.
2. Prevent adjustments of font size after orientation changes in iOS.
3. Use a more readable tab size.
4. Use the user's configured `sans` font-family by default.
5. Use the user's configured `sans` font-feature-settings by default.
6. Use the user's configured `sans` font-variation-settings by default.
7. Disable tap highlights on iOS
*/

html,
:host {
  line-height: 1.5;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -moz-tab-size: 4;
  /* 3 */
  -o-tab-size: 4;
  tab-size: 4;
  /* 3 */
  font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  /* 4 */
  font-feature-settings: normal;
  /* 5 */
  font-variation-settings: normal;
  /* 6 */
  -webkit-tap-highlight-color: transparent;
  /* 7 */
}

/*
1. Remove the margin in all browsers.
2. Inherit line-height from `html` so users can set them as a class directly on the `html` element.
*/

body {
  margin: 0;
  /* 1 */
  line-height: inherit;
  /* 2 */
}

/*
1. Add the correct height in Firefox.
2. Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
3. Ensure horizontal rules are visible by default.
*/

hr {
  height: 0;
  /* 1 */
  color: inherit;
  /* 2 */
  border-top-width: 1px;
  /* 3 */
}

/*
Add the correct text decoration in Chrome, Edge, and Safari.
*/

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

/*
Remove the default font size and weight for headings.
*/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

/*
Reset links to optimize for opt-in styling instead of opt-out.
*/

a {
  color: inherit;
  text-decoration: inherit;
}

/*
Add the correct font weight in Edge and Safari.
*/

b,
strong {
  font-weight: bolder;
}

/*
1. Use the user's configured `mono` font-family by default.
2. Use the user's configured `mono` font-feature-settings by default.
3. Use the user's configured `mono` font-variation-settings by default.
4. Correct the odd `em` font sizing in all browsers.
*/

code,
kbd,
samp,
pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  /* 1 */
  font-feature-settings: normal;
  /* 2 */
  font-variation-settings: normal;
  /* 3 */
  font-size: 1em;
  /* 4 */
}

/*
Add the correct font size in all browsers.
*/

small {
  font-size: 80%;
}

/*
Prevent `sub` and `sup` elements from affecting the line height in all browsers.
*/

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/*
1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
3. Remove gaps between table borders by default.
*/

table {
  text-indent: 0;
  /* 1 */
  border-color: inherit;
  /* 2 */
  border-collapse: collapse;
  /* 3 */
}

/*
1. Change the font styles in all browsers.
2. Remove the margin in Firefox and Safari.
3. Remove default padding in all browsers.
*/

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-feature-settings: inherit;
  /* 1 */
  font-variation-settings: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  font-weight: inherit;
  /* 1 */
  line-height: inherit;
  /* 1 */
  color: inherit;
  /* 1 */
  margin: 0;
  /* 2 */
  padding: 0;
  /* 3 */
}

/*
Remove the inheritance of text transform in Edge and Firefox.
*/

button,
select {
  text-transform: none;
}

/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Remove default button styles.
*/

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
  /* 1 */
  background-color: transparent;
  /* 2 */
  background-image: none;
  /* 2 */
}

/*
Use the modern Firefox focus style for all focusable elements.
*/

:-moz-focusring {
  outline: auto;
}

/*
Remove the additional `:invalid` styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
*/

:-moz-ui-invalid {
  box-shadow: none;
}

/*
Add the correct vertical alignment in Chrome and Firefox.
*/

progress {
  vertical-align: baseline;
}

/*
Correct the cursor style of increment and decrement buttons in Safari.
*/

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/*
1. Correct the odd appearance in Chrome and Safari.
2. Correct the outline style in Safari.
*/

[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/*
Remove the inner padding in Chrome and Safari on macOS.
*/

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Change font properties to `inherit` in Safari.
*/

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/*
Add the correct display in Chrome and Safari.
*/

summary {
  display: list-item;
}

/*
Removes the default spacing and border for appropriate elements.
*/

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

/*
Reset default styling for dialogs.
*/

dialog {
  padding: 0;
}

/*
Prevent resizing textareas horizontally by default.
*/

textarea {
  resize: vertical;
}

/*
1. Reset the default placeholder opacity in Firefox. (https://github.com/tailwindlabs/tailwindcss/issues/3300)
2. Set the default placeholder color to the user's configured gray 400 color.
*/

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  /* 1 */
  color: #9ca3af;
  /* 2 */
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  /* 1 */
  color: #9ca3af;
  /* 2 */
}

/*
Set the default cursor for buttons.
*/

button,
[role="button"] {
  cursor: pointer;
}

/*
Make sure disabled buttons don't get the pointer cursor.
*/

:disabled {
  cursor: default;
}

/*
1. Make replaced elements `display: block` by default. (https://github.com/mozdevs/cssremedy/issues/14)
2. Add `vertical-align: middle` to align replaced elements more sensibly by default. (https://github.com/jensimmons/cssremedy/issues/14#issuecomment-634934210)
   This can trigger a poorly considered lint error in some tools but is included by design.
*/

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  /* 1 */
  vertical-align: middle;
  /* 2 */
}

/*
Constrain images and videos to the parent width and preserve their intrinsic aspect ratio. (https://github.com/mozdevs/cssremedy/issues/14)
*/

img,
video {
  max-width: 100%;
  height: auto;
}

/* Make elements with the HTML hidden attribute stay hidden by default */

[hidden] {
  display: none;
}

body,
.page-wrapper {
  min-height: 100vh;
  font-family: Inter, sans-serif;
  font-size: 1.125rem;
  line-height: 1.75rem;
  line-height: 1.66;
  color: rgb(10 16 47 / 0.8);
}

/* Default Heading */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Public Sans, sans-serif;
  font-weight: 800;
  line-height: 1.25;
  letter-spacing: -2px;
  --tw-text-opacity: 1;
  color: rgb(10 16 47 / var(--tw-text-opacity));
}

h1 {
  font-size: 46px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 3rem;
    line-height: 1;
  }
}

@media (min-width: 992px) {
  h1 {
    font-size: 68px;
  }
}

@media (min-width: 1200px) {
  h1 {
    font-size: 80px;
  }
}

@media (min-width: 1400px) {
  h1 {
    font-size: 100px;
  }
}

h2 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  line-height: 1.26;
  letter-spacing: 0em;
}

@media (min-width: 768px) {
  h2 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

@media (min-width: 992px) {
  h2 {
    line-height: 1.26;
  }
}

@media (min-width: 1200px) {
  h2 {
    font-size: 46px;
  }
}

h4 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  line-height: 1.33;
  letter-spacing: 0em;
}

h5 {
  font-size: 1.5rem;
  line-height: 2rem;
  line-height: 1.25;
  letter-spacing: 0em;
}

h6 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  line-height: 1.4;
  letter-spacing: 0em;
}

a,
button {
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

p {
  margin-bottom: 2rem;
}

p:last-child {
  margin-bottom: 0px;
}

*,
::before,
::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 576px) {
  .container {
    max-width: 576px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 992px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1400px;
  }
}

.btn {
  position: relative;
  border-width: 2px;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  text-align: center;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
  transition-property: all;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@media (min-width: 1200px) {
  .is-large {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.btn-animation {
  overflow: hidden;
}

.btn-animation::before {
  position: absolute;
  left: -10%;
  top: -110%;
  height: 0px;
  width: 120%;
  border-radius: 50%;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-bottom: 120%;
  content: var(--tw-content);
  transform: translate3d(0, 68%, 0) scale3d(0, 0, 0);
}

.btn-animation::after {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  transform: translate3d(0, -100%, 0);
  content: var(--tw-content);
  transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
}

.btn-animation:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(10 16 47 / var(--tw-bg-opacity));
}

.btn-animation:hover::before {
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  content: var(--tw-content);
  transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
}

.btn-animation:hover::after {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 400ms;
  transition-duration: 500ms;
  transition-timing-function: linear;
  content: var(--tw-content);
  transform: translate3d(0, 0, 0);
}

.is-transparent::before {
  content: var(--tw-content);
  background-color: transparent;
}

.is-transparent::after {
  content: var(--tw-content);
  background-color: transparent;
}

.btn-animation span {
  position: relative;
  z-index: 10;
  display: block;
}

.btn-animation:hover span {
  animation: fill-up-initial 0.3s forwards, fill-up-end 0.3s forwards 0.3s;
}

.btn-text {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  transition-property: all;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.is-blue {
  --tw-border-opacity: 1;
  border-color: rgb(var(--primary-color-rgb) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(var(--primary-color-rgb) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.is-blue:hover {
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgb(var(--primary-color-rgb) / var(--tw-text-opacity));
}

.is-purple {
  --tw-border-opacity: 1;
  border-color: rgb(var(--secondary-color-rgb) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(var(--secondary-color-rgb) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.is-purple:hover {
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgb(var(--secondary-color-rgb) / var(--tw-text-opacity));
}

.is-black {
  --tw-border-opacity: 1;
  border-color: rgb(10 16 47 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(10 16 47 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.is-black:hover {
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgb(10 16 47 / var(--tw-text-opacity));
}

.is-yellow {
  --tw-border-opacity: 1;
  border-color: rgb(10 16 47 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 201 0 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(10 16 47 / var(--tw-text-opacity));
}

.is-violet {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(75 42 173 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.is-atomic-tangerine {
  --tw-border-opacity: 1;
  border-color: rgb(var(--primary-color-rgb) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(var(--primary-color-rgb) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.is-atomic-tangerine:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.is-lime {
  --tw-border-opacity: 1;
  border-color: var(--primary-color);
  --tw-bg-opacity: 1;
  background-color: var(--primary-color);
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  color: white;
}

.is-lime:hover {
  --tw-border-opacity: 1;
  border-color: rgb(10 16 47 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  color: #121212;
}

.is-lunar-green {
  --tw-border-opacity: 1;
  border-color: rgb(50 68 56 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(50 68 56 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(244 185 5 / var(--tw-text-opacity));
}

.is-lunar-green:hover {
  --tw-border-opacity: 1;
  border-color: rgb(10 16 47 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(244 185 5 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(50 68 56 / var(--tw-text-opacity));
}

.is-denim {
  --tw-border-opacity: 1;
  border-color: rgb(45 43 146 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(45 43 146 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 216 116 / var(--tw-text-opacity));
}

.is-denim:hover {
  --tw-border-opacity: 1;
  border-color: rgb(45 43 146 / var(--tw-border-opacity));
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgb(45 43 146 / var(--tw-text-opacity));
}

.is-pale-gold {
  --tw-border-opacity: 1;
  border-color: rgb(255 216 116 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 216 116 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(45 43 146 / var(--tw-text-opacity));
}

.is-pale-gold:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 216 116 / var(--tw-border-opacity));
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgb(255 216 116 / var(--tw-text-opacity));
}

.is-caribbean-green {
  --tw-border-opacity: 1;
  border-color: rgb(21 207 146 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(21 207 146 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(1 13 9 / var(--tw-text-opacity));
}

.is-caribbean-green:hover {
  --tw-border-opacity: 1;
  border-color: rgb(21 207 146 / var(--tw-border-opacity));
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgb(21 207 146 / var(--tw-text-opacity));
}

.is-honey-suckle {
  --tw-border-opacity: 1;
  border-color: rgb(233 255 151 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(233 255 151 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(43 19 185 / var(--tw-text-opacity));
}

.is-honey-suckle:hover {
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgb(233 255 151 / var(--tw-text-opacity));
}

.is-honey-suckle-2 {
  --tw-border-opacity: 1;
  border-color: rgb(233 255 151 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(233 255 151 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(43 19 185 / var(--tw-text-opacity));
}

.is-honey-suckle-2:hover {
  --tw-border-opacity: 1;
  border-color: rgb(43 19 185 / var(--tw-border-opacity));
  background-color: transparent;
  --tw-bg-opacity: 1;
  background-color: rgb(43 19 185 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(233 255 151 / var(--tw-text-opacity));
}

.is-outline-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.is-outline-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(10 16 47 / var(--tw-text-opacity));
}

.is-outline-black {
  --tw-border-opacity: 1;
  border-color: rgb(10 16 47 / var(--tw-border-opacity));
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgb(10 16 47 / var(--tw-text-opacity));
}

.is-outline-black:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(10 16 47 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(10 16 47 / var(--tw-text-opacity));
}

.is-outline-denim {
  --tw-border-opacity: 1;
  border-color: rgb(45 43 146 / var(--tw-border-opacity));
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgb(45 43 146 / var(--tw-text-opacity));
}

.is-outline-denim:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(45 43 146 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 216 116 / var(--tw-text-opacity));
}

.is-outline-palegold {
  --tw-border-opacity: 1;
  border-color: rgb(255 216 116 / var(--tw-border-opacity));
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgb(255 216 116 / var(--tw-text-opacity));
}

.is-outline-palegold::before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 216 116 / var(--tw-bg-opacity));
}

.is-outline-palegold::after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 216 116 / var(--tw-bg-opacity));
}

.is-outline-palegold:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 216 116 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(45 43 146 / var(--tw-text-opacity));
}

.is-outline-midnight-moss {
  --tw-border-opacity: 1;
  border-color: rgb(1 13 9 / var(--tw-border-opacity));
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgb(1 13 9 / var(--tw-text-opacity));
}

.is-outline-midnight-moss::before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(1 13 9 / var(--tw-bg-opacity));
}

.is-outline-midnight-moss::after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(1 13 9 / var(--tw-bg-opacity));
}

.is-outline-midnight-moss:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(1 13 9 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.tab-btn-blue {
  border-style: none;
  background-color: rgb(10 16 47 / 0.05);
  color: rgb(10 16 47 / 0.6);
}

.tab-btn-blue:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--primary-color-rgb) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.tab-btn-blue.active {
  border-style: none;
  --tw-bg-opacity: 1;
  background-color: rgb(var(--primary-color-rgb) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.is-white {
  --tw-border-opacity: 1;
  border-color: rgb(10 16 47 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(10 16 47 / var(--tw-text-opacity));
}

.is-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(10 16 47 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.is-dark {
  --tw-border-opacity: 1;
  border-color: rgb(25 25 25 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(25 25 25 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.is-rounded {
  border-radius: 50px;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0px;
}

.-bottom-1 {
  bottom: -0.25rem;
}

.-bottom-12 {
  bottom: -3rem;
}

.-left-1 {
  left: -0.25rem;
}

.-left-10 {
  left: -2.5rem;
}

.-left-14 {
  left: -3.5rem;
}

.-left-16 {
  left: -4rem;
}

.-left-20 {
  left: -5rem;
}

.-left-\[12\%\] {
  left: -12%;
}

.-left-\[13\%\] {
  left: -13%;
}

.-left-\[35px\] {
  left: -35px;
}

.-right-16 {
  right: -4rem;
}

.-right-4 {
  right: -1rem;
}

.-right-\[110px\] {
  right: -110px;
}

.-right-\[18\%\] {
  right: -18%;
}

.-right-\[39px\] {
  right: -39px;
}

.-right-\[45px\] {
  right: -45px;
}

.-top-16 {
  top: -4rem;
}

.-top-8 {
  top: -2rem;
}

.-top-\[3px\] {
  top: -3px;
}

.-top-\[70px\] {
  top: -70px;
}

.bottom-0 {
  bottom: 0px;
}

.bottom-10 {
  bottom: 2.5rem;
}

.bottom-12 {
  bottom: 3rem;
}

.bottom-6 {
  bottom: 1.5rem;
}

.bottom-\[18\%\] {
  bottom: 18%;
}

.bottom-\[5px\] {
  bottom: 5px;
}

.bottom-\[60px\] {
  bottom: 60px;
}

.bottom-\[90px\] {
  bottom: 90px;
}

.left-0 {
  left: 0px;
}

.left-1\/2 {
  left: 50%;
}

.left-5 {
  left: 1.25rem;
}

.left-\[152px\] {
  left: 152px;
}

.left-\[164px\] {
  left: 164px;
}

.left-\[30px\] {
  left: 30px;
}

.left-\[calc\(50\%-_40px\)\] {
  left: calc(50% - 40px);
}

.left-full {
  left: 100%;
}

.right-0 {
  right: 0px;
}

.right-12 {
  right: 3rem;
}

.right-32 {
  right: 8rem;
}

.right-5 {
  right: 1.25rem;
}

.right-\[10px\] {
  right: 10px;
}

.right-\[57px\] {
  right: 57px;
}

.right-\[5px\] {
  right: 5px;
}

.right-\[60px\] {
  right: 60px;
}

.right-\[9\%\] {
  right: 9%;
}

.top-0 {
  top: 0px;
}

.top-1 {
  top: 0.25rem;
}

.top-1\/2 {
  top: 50%;
}

.top-1\/3 {
  top: 33.333333%;
}

.top-1\/4 {
  top: 25%;
}

.top-10 {
  top: 2.5rem;
}

.top-14 {
  top: 3.5rem;
}

.top-28 {
  top: 7rem;
}

.top-8 {
  top: 2rem;
}

.top-\[100px\] {
  top: 100px;
}

.top-\[10px\] {
  top: 10px;
}

.top-\[15\%\] {
  top: 15%;
}

.top-\[150px\] {
  top: 150px;
}

.top-\[197px\] {
  top: 197px;
}

.top-\[35px\] {
  top: 35px;
}

.top-\[44px\] {
  top: 44px;
}

.top-\[5px\] {
  top: 5px;
}

.top-\[70px\] {
  top: 70px;
}

.-z-10 {
  z-index: -10;
}

.-z-\[1\] {
  z-index: -1;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-\[1\] {
  z-index: 1;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.m-0 {
  margin: 0px;
}

.-my-10 {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem;
}

.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-\[30px\] {
  margin-top: 30px;
  margin-bottom: 30px;
}

.my-\[50px\] {
  margin-top: 50px;
  margin-bottom: 50px;
}

.my-\[60px\] {
  margin-top: 60px;
  margin-bottom: 60px;
}

.-mb-20 {
  margin-bottom: -5rem;
}

.-mb-\[17px\] {
  margin-bottom: -17px;
}

.-mb-\[60px\] {
  margin-bottom: -60px;
}

.-mb-px {
  margin-bottom: -1px;
}

.-ml-\[15px\] {
  margin-left: -15px;
}

.-mt-\[6px\] {
  margin-top: -6px;
}

.-mt-px {
  margin-top: -1px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.mb-\[10px\] {
  margin-bottom: 10px;
}

.mb-\[15px\] {
  margin-bottom: 15px;
}

.mb-\[18px\] {
  margin-bottom: 18px;
}

.mb-\[25px\] {
  margin-bottom: 25px;
}

.mb-\[30px\] {
  margin-bottom: 30px;
}

.mb-\[50px\] {
  margin-bottom: 50px;
}

.mb-\[5px\] {
  margin-bottom: 5px;
}

.mb-\[60px\] {
  margin-bottom: 60px;
}

.ml-16 {
  margin-left: 4rem;
}

.ml-\[10px\] {
  margin-left: 10px;
}

.ml-\[35px\] {
  margin-left: 35px;
}

.ml-auto {
  margin-left: auto;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mr-\[10px\] {
  margin-right: 10px;
}

.mr-\[5px\] {
  margin-right: 5px;
}

.mr-auto {
  margin-right: auto;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[15px\] {
  margin-top: 15px;
}

.mt-\[30px\] {
  margin-top: 30px;
}

.mt-\[3px\] {
  margin-top: 3px;
}

.mt-\[50px\] {
  margin-top: 50px;
}

.mt-\[60px\] {
  margin-top: 60px;
}

.mt-auto {
  margin-top: auto;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.line-clamp-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-0 {
  height: 0px;
}

.h-0\.5 {
  height: 0.125rem;
}

.h-1 {
  height: 0.25rem;
}

.h-1\/2 {
  height: 50%;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-20 {
  height: 5rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-64 {
  height: 16rem;
}

.h-8 {
  height: 2rem;
}

.h-96 {
  height: 24rem;
}

.h-\[100px\] {
  height: 100px;
}

.h-\[10px\] {
  height: 10px;
}

.h-\[150px\] {
  height: 150px;
}

.h-\[152px\] {
  height: 152px;
}

.h-\[1px\] {
  height: 1px;
}

.h-\[200px\] {
  height: 200px;
}

.h-\[230px\] {
  height: 230px;
}

.h-\[2px\] {
  height: 2px;
}

.h-\[30px\] {
  height: 30px;
}

.h-\[35px\] {
  height: 35px;
}

.h-\[3px\] {
  height: 3px;
}

.h-\[43px\] {
  height: 43px;
}

.h-\[50px\] {
  height: 50px;
}

.h-\[58px\] {
  height: 58px;
}

.h-\[60px\] {
  height: 60px;
}

.h-\[635px\] {
  height: 635px;
}

.h-\[66px\] {
  height: 66px;
}

.h-\[70px\] {
  height: 70px;
}

.h-\[78px\] {
  height: 78px;
}

.h-\[80px\] {
  height: 80px;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-px {
  height: 1px;
}

.min-h-\[120px\] {
  min-height: 120px;
}

.min-h-\[130px\] {
  min-height: 130px;
}

.min-h-\[150px\] {
  min-height: 150px;
}

.min-h-\[65px\] {
  min-height: 65px;
}

.w-0 {
  width: 0px;
}

.w-0\.5 {
  width: 0.125rem;
}

.w-1 {
  width: 0.25rem;
}

.w-10 {
  width: 2.5rem;
}

.w-10\/12 {
  width: 83.333333%;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-20 {
  width: 5rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-64 {
  width: 16rem;
}

.w-8 {
  width: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.w-\[100px\] {
  width: 100px;
}

.w-\[10px\] {
  width: 10px;
}

.w-\[150px\] {
  width: 150px;
}

.w-\[152px\] {
  width: 152px;
}

.w-\[230px\] {
  width: 230px;
}

.w-\[25\%\] {
  width: 25%;
}

.w-\[306px\] {
  width: 306px;
}

.w-\[30px\] {
  width: 30px;
}

.w-\[35px\] {
  width: 35px;
}

.w-\[43px\] {
  width: 43px;
}

.w-\[50\%\] {
  width: 50%;
}

.w-\[50px\] {
  width: 50px;
}

.w-\[58px\] {
  width: 58px;
}

.w-\[60px\] {
  width: 60px;
}

.w-\[66px\] {
  width: 66px;
}

.w-\[70\%\] {
  width: 70%;
}

.w-\[70px\] {
  width: 70px;
}

.w-\[74px\] {
  width: 74px;
}

.w-\[78px\] {
  width: 78px;
}

.w-\[80px\] {
  width: 80px;
}

.w-auto {
  width: auto;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.min-w-\[855px\] {
  min-width: 855px;
}

.max-w-\[100px\] {
  max-width: 100px;
}

.max-w-\[1076px\] {
  max-width: 1076px;
}

.max-w-\[1080px\] {
  max-width: 1080px;
}

.max-w-\[1156px\] {
  max-width: 1156px;
}

.max-w-\[1296px\] {
  max-width: 1296px;
}

.max-w-\[1500px\] {
  max-width: 1500px;
}

.max-w-\[170px\] {
  max-width: 170px;
}

.max-w-\[280px\] {
  max-width: 280px;
}

.max-w-\[374px\] {
  max-width: 374px;
}

.max-w-\[376px\] {
  max-width: 376px;
}

.max-w-\[400px\] {
  max-width: 400px;
}

.max-w-\[416px\] {
  max-width: 416px;
}

.max-w-\[480px\] {
  max-width: 480px;
}

.max-w-\[486px\] {
  max-width: 486px;
}

.max-w-\[500px\] {
  max-width: 500px;
}

.max-w-\[526px\] {
  max-width: 526px;
}

.max-w-\[535px\] {
  max-width: 535px;
}

.max-w-\[536px\] {
  max-width: 536px;
}

.max-w-\[548px\] {
  max-width: 548px;
}

.max-w-\[550px\] {
  max-width: 550px;
}

.max-w-\[576px\] {
  max-width: 576px;
}

.max-w-\[580px\] {
  max-width: 580px;
}

.max-w-\[584px\] {
  max-width: 584px;
}

.max-w-\[590px\] {
  max-width: 590px;
}

.max-w-\[600px\] {
  max-width: 600px;
}

.max-w-\[605px\] {
  max-width: 605px;
}

.max-w-\[607px\] {
  max-width: 607px;
}

.max-w-\[608px\] {
  max-width: 608px;
}

.max-w-\[616px\] {
  max-width: 616px;
}

.max-w-\[624px\] {
  max-width: 624px;
}

.max-w-\[625px\] {
  max-width: 625px;
}

.max-w-\[627px\] {
  max-width: 627px;
}

.max-w-\[636px\] {
  max-width: 636px;
}

.max-w-\[640px\] {
  max-width: 640px;
}

.max-w-\[658px\] {
  max-width: 658px;
}

.max-w-\[670px\] {
  max-width: 670px;
}

.max-w-\[688px\] {
  max-width: 688px;
}

.max-w-\[695px\] {
  max-width: 695px;
}

.max-w-\[700px\] {
  max-width: 700px;
}

.max-w-\[718px\] {
  max-width: 718px;
}

.max-w-\[720px\] {
  max-width: 720px;
}

.max-w-\[746px\] {
  max-width: 746px;
}

.max-w-\[750px\] {
  max-width: 750px;
}

.max-w-\[789px\] {
  max-width: 789px;
}

.max-w-\[80\%\] {
  max-width: 80%;
}

.max-w-\[800px\] {
  max-width: 800px;
}

.max-w-\[826px\] {
  max-width: 826px;
}

.max-w-\[843px\] {
  max-width: 843px;
}

.max-w-\[856px\] {
  max-width: 856px;
}

.max-w-\[898px\] {
  max-width: 898px;
}

.max-w-\[90\%\] {
  max-width: 90%;
}

.max-w-\[900px\] {
  max-width: 900px;
}

.max-w-\[940px\] {
  max-width: 940px;
}

.max-w-\[996px\] {
  max-width: 996px;
}

.max-w-full {
  max-width: 100%;
}

.max-w-none {
  max-width: none;
}

.max-w-xl {
  max-width: 36rem;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-none {
  flex: none;
}

.grow {
  flex-grow: 1;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-1 {
  --tw-translate-x: 0.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[140px\] {
  --tw-translate-x: 140px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[5px\] {
  --tw-translate-x: 5px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1 {
  --tw-translate-y: 0.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-16 {
  --tw-translate-y: 4rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-8 {
  --tw-translate-y: 2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[5px\] {
  --tw-translate-y: 5px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-full {
  --tw-translate-y: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-\[30deg\] {
  --tw-rotate: -30deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-\[5deg\] {
  --tw-rotate: -5deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[29deg\] {
  --tw-rotate: 29deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[5deg\] {
  --tw-rotate: 5deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-x-0 {
  --tw-scale-x: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.list-inside {
  list-style-position: inside;
}

.list-decimal {
  list-style-type: decimal;
}

.list-disc {
  list-style-type: disc;
}

.columns-1 {
  -moz-columns: 1;
  columns: 1;
}

.break-inside-auto {
  -moz-column-break-inside: auto;
  break-inside: auto;
}

.break-inside-avoid {
  -moz-column-break-inside: avoid;
  break-inside: avoid;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-14 {
  gap: 3.5rem;
}

.gap-16 {
  gap: 4rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-20 {
  gap: 5rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-\[10px\] {
  gap: 10px;
}

.gap-\[134px\] {
  gap: 134px;
}

.gap-\[18px\] {
  gap: 18px;
}

.gap-\[30px\] {
  gap: 30px;
}

.gap-\[34px\] {
  gap: 34px;
}

.gap-\[50px\] {
  gap: 50px;
}

.gap-\[5px\] {
  gap: 5px;
}

.gap-\[60px\] {
  gap: 60px;
}

.gap-\[7px\] {
  gap: 7px;
}

.gap-px {
  gap: 1px;
}

.gap-x-1 {
  -moz-column-gap: 0.25rem;
  column-gap: 0.25rem;
}

.gap-x-10 {
  -moz-column-gap: 2.5rem;
  column-gap: 2.5rem;
}

.gap-x-12 {
  -moz-column-gap: 3rem;
  column-gap: 3rem;
}

.gap-x-16 {
  -moz-column-gap: 4rem;
  column-gap: 4rem;
}

.gap-x-2 {
  -moz-column-gap: 0.5rem;
  column-gap: 0.5rem;
}

.gap-x-20 {
  -moz-column-gap: 5rem;
  column-gap: 5rem;
}

.gap-x-3 {
  -moz-column-gap: 0.75rem;
  column-gap: 0.75rem;
}

.gap-x-4 {
  -moz-column-gap: 1rem;
  column-gap: 1rem;
}

.gap-x-5 {
  -moz-column-gap: 1.25rem;
  column-gap: 1.25rem;
}

.gap-x-6 {
  -moz-column-gap: 1.5rem;
  column-gap: 1.5rem;
}

.gap-x-8 {
  -moz-column-gap: 2rem;
  column-gap: 2rem;
}

.gap-x-\[10px\] {
  -moz-column-gap: 10px;
  column-gap: 10px;
}

.gap-x-\[15px\] {
  -moz-column-gap: 15px;
  column-gap: 15px;
}

.gap-x-\[17px\] {
  -moz-column-gap: 17px;
  column-gap: 17px;
}

.gap-x-\[200px\] {
  -moz-column-gap: 200px;
  column-gap: 200px;
}

.gap-x-\[22px\] {
  -moz-column-gap: 22px;
  column-gap: 22px;
}

.gap-x-\[30px\] {
  -moz-column-gap: 30px;
  column-gap: 30px;
}

.gap-x-\[50px\] {
  -moz-column-gap: 50px;
  column-gap: 50px;
}

.gap-x-\[60px\] {
  -moz-column-gap: 60px;
  column-gap: 60px;
}

.gap-y-1 {
  row-gap: 0.25rem;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

.gap-y-12 {
  row-gap: 3rem;
}

.gap-y-2 {
  row-gap: 0.5rem;
}

.gap-y-20 {
  row-gap: 5rem;
}

.gap-y-3 {
  row-gap: 0.75rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.gap-y-7 {
  row-gap: 1.75rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.gap-y-9 {
  row-gap: 2.25rem;
}

.gap-y-\[10px\] {
  row-gap: 10px;
}

.gap-y-\[30px\] {
  row-gap: 30px;
}

.gap-y-\[40px\] {
  row-gap: 40px;
}

.gap-y-\[50px\] {
  row-gap: 50px;
}

.gap-y-\[5\] {
  row-gap: 5;
}

.gap-y-\[5px\] {
  row-gap: 5px;
}

.gap-y-px {
  row-gap: 1px;
}

.overflow-hidden {
  overflow: hidden;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-\[10px\] {
  border-radius: 10px;
}

.rounded-\[30px\] {
  border-radius: 30px;
}

.rounded-\[35px\] {
  border-radius: 35px;
}

.rounded-\[3px\] {
  border-radius: 3px;
}

.rounded-\[50\%\] {
  border-radius: 50%;
}

.rounded-\[50px\] {
  border-radius: 50px;
}

.rounded-\[55px\] {
  border-radius: 55px;
}

.rounded-\[5px\] {
  border-radius: 5px;
}

.rounded-\[6px\] {
  border-radius: 6px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-sm {
  border-radius: 0.125rem;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.rounded-bl-\[30px\] {
  border-bottom-left-radius: 30px;
}

.rounded-br-\[30px\] {
  border-bottom-right-radius: 30px;
}

.rounded-tl-\[10px\] {
  border-top-left-radius: 10px;
}

.rounded-tl-\[30px\] {
  border-top-left-radius: 30px;
}

.rounded-tr-\[10px\] {
  border-top-right-radius: 10px;
}

.rounded-tr-\[30px\] {
  border-top-right-radius: 30px;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-\[10px\] {
  border-width: 10px;
}

.border-\[3px\] {
  border-width: 3px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b-4 {
  border-bottom-width: 4px;
}

.border-b-\[5px\] {
  border-bottom-width: 5px;
}

.border-t {
  border-top-width: 1px;
}

.border-dashed {
  border-style: dashed;
}

.border-none {
  border-style: none;
}

.border-ColorBlack {
  --tw-border-opacity: 1;
  border-color: rgb(10 16 47 / var(--tw-border-opacity));
}

.border-ColorBlack\/10 {
  border-color: rgb(10 16 47 / 0.1);
}

.border-ColorBlack\/20 {
  border-color: rgb(10 16 47 / 0.2);
}

.border-ColorBlack\/50 {
  border-color: rgb(10 16 47 / 0.5);
}

.border-ColorDark {
  --tw-border-opacity: 1;
  border-color: rgb(25 25 25 / var(--tw-border-opacity));
}
.border-ColorPrimary {
  --tw-border-opacity: 1;
  border-color: rgb(var(--primary-color-rgb) / var(--tw-border-opacity));
}

.border-ColorDenimDarkBlue {
  --tw-border-opacity: 1;
  border-color: rgb(var(--primary-color-rgb) / var(--tw-border-opacity));
}

.border-ColorMidnightMoss {
  --tw-border-opacity: 1;
  border-color: rgb(1 13 9 / var(--tw-border-opacity));
}

.border-ColorOil {
  --tw-border-opacity: 1;
  border-color: rgb(34 31 26 / var(--tw-border-opacity));
}

.border-ColorOil\/10 {
  border-color: rgb(34 31 26 / 0.1);
}

.border-\[\#0000FF\] {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 255 / var(--tw-border-opacity));
}

.border-\[\#0E0E0E\] {
  --tw-border-opacity: 1;
  border-color: rgb(14 14 14 / var(--tw-border-opacity));
}

.border-\[\#121212\] {
  --tw-border-opacity: 1;
  border-color: rgb(18 18 18 / var(--tw-border-opacity));
}

.border-\[\#414141\] {
  --tw-border-opacity: 1;
  border-color: rgb(65 65 65 / var(--tw-border-opacity));
}

.border-\[\#B8C1CC\] {
  --tw-border-opacity: 1;
  border-color: rgb(184 193 204 / var(--tw-border-opacity));
}

.border-\[\#CBD2D9\] {
  --tw-border-opacity: 1;
  border-color: rgb(203 210 217 / var(--tw-border-opacity));
}

.border-\[\#E1E1E1\] {
  --tw-border-opacity: 1;
  border-color: rgb(225 225 225 / var(--tw-border-opacity));
}

.border-\[\#E1E1E\] {
  border-color: #e1e1e1;
}

.border-\[\#E6E6E6\] {
  --tw-border-opacity: 1;
  border-color: rgb(230 230 230 / var(--tw-border-opacity));
}

.border-\[\#EAEDF0\] {
  --tw-border-opacity: 1;
  border-color: rgb(234 237 240 / var(--tw-border-opacity));
}

.border-\[\#F4F4F4\] {
  --tw-border-opacity: 1;
  border-color: rgb(244 244 244 / var(--tw-border-opacity));
}

.border-\[\#FDFBF9\]\/10 {
  border-color: rgb(253 251 249 / 0.1);
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-white\/5 {
  border-color: rgb(255 255 255 / 0.05);
}
.bg-ColorPrimary {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--primary-color-rgb) / var(--tw-bg-opacity));
}
.bg-ColorPrimaryLight {
  --tw-bg-opacity: 1;
  background-color: var(--bg-primary-color-lighter);
}
.bg-ColorAlmond {
  --tw-bg-opacity: 1;
  background-color: rgb(235 222 206 / var(--tw-bg-opacity));
}

.bg-ColorAtomicTangerine {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--primary-color-rgb) / var(--tw-bg-opacity));
}

.bg-ColorBlack {
  --tw-bg-opacity: 1;
  background-color: rgb(10 16 47 / var(--tw-bg-opacity));
}

.bg-ColorBlack\/10 {
  background-color: rgb(10 16 47 / 0.1);
}

.bg-ColorBlack\/5 {
  background-color: rgb(10 16 47 / 0.05);
}

.bg-ColorBlue {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--primary-color-rgb) / var(--tw-bg-opacity));
}

.bg-ColorBluePurple {
  --tw-bg-opacity: 1;
  background-color: rgb(121 73 246 / var(--tw-bg-opacity));
}

.bg-ColorCaribbeanGreen {
  --tw-bg-opacity: 1;
  background-color: rgb(21 207 146 / var(--tw-bg-opacity));
}

.bg-ColorCorn {
  --tw-bg-opacity: 1;
  background-color: rgb(244 185 5 / var(--tw-bg-opacity));
}

.bg-ColorDark {
  --tw-bg-opacity: 1;
  background-color: rgb(25 25 25 / var(--tw-bg-opacity));
}

.bg-ColorDark\/10 {
  background-color: rgb(25 25 25 / 0.1);
}

.bg-ColorDenimDarkBlue {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--primary-color-rgb) / var(--tw-bg-opacity));
}

.bg-ColorEggSour {
  --tw-bg-opacity: 1;
  background-color: var(--bg-primary-color-light);
}

.bg-ColorHoneySuckle {
  --tw-bg-opacity: 1;
  background-color: rgb(233 255 151 / var(--tw-bg-opacity));
}

.bg-ColorLime {
  --tw-bg-opacity: 1;
  background-color: rgb(1 231 154 / var(--tw-bg-opacity));
}

.bg-ColorLunarGreen {
  --tw-bg-opacity: 1;
  background-color: rgb(50 68 56 / var(--tw-bg-opacity));
}

.bg-ColorMidnightMoss {
  --tw-bg-opacity: 1;
  background-color: rgb(1 13 9 / var(--tw-bg-opacity));
}

.bg-ColorMidnightMoss\/10 {
  background-color: rgb(1 13 9 / 0.1);
}

.bg-ColorOffWhite {
  --tw-bg-opacity: 1;
  background-color: #efefef;
}


.bg-ColorOil {
  --tw-bg-opacity: 1;
  background-color: rgb(34 31 26 / var(--tw-bg-opacity));
}

.bg-ColorOil\/10 {
  background-color: rgb(34 31 26 / 0.1);
}

.bg-ColorPaleGold {
  --tw-bg-opacity: 1;
  background-color: rgb(255 216 116 / var(--tw-bg-opacity));
}

.bg-ColorPurple {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--secondary-color-rgb) / var(--tw-bg-opacity));
}

.bg-ColorGray {
  --tw-bg-opacity: 1;
  background-color: #efefef;
}

.bg-ColorPurple\/5 {
  background-color: rgb(var(--secondary-color-rgb) / 0.05);
}

.bg-ColorYellow {
  --tw-bg-opacity: 1;
  background-color: rgb(255 201 0 / var(--tw-bg-opacity));
}

.bg-\[\#0000FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 255 / var(--tw-bg-opacity));
}

.bg-\[\#0A102F\] {
  --tw-bg-opacity: 1;
  background-color: rgb(10 16 47 / var(--tw-bg-opacity));
}

.bg-\[\#0E0E0E\] {
  --tw-bg-opacity: 1;
  background-color: rgb(14 14 14 / var(--tw-bg-opacity));
}

.bg-\[\#0E0E0E\]\/10 {
  background-color: rgb(14 14 14 / 0.1);
}

.bg-\[\#0F1642\] {
  --tw-bg-opacity: 1;
  background-color: rgb(15 22 66 / var(--tw-bg-opacity));
}

.bg-\[\#121212\] {
  --tw-bg-opacity: 1;
  background-color: rgb(18 18 18 / var(--tw-bg-opacity));
}

.bg-\[\#1B1C1D\] {
  --tw-bg-opacity: 1;
  background-color: rgb(27 28 29 / var(--tw-bg-opacity));
}

.bg-\[\#2B13B9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(43 19 185 / var(--tw-bg-opacity));
}

.bg-\[\#2C2C2C\] {
  --tw-bg-opacity: 1;
  background-color: rgb(44 44 44 / var(--tw-bg-opacity));
}

.bg-\[\#48C96C\] {
  --tw-bg-opacity: 1;
  background-color: rgb(72 201 108 / var(--tw-bg-opacity));
}

.bg-\[\#A2DFF5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(162 223 245 / var(--tw-bg-opacity));
}

.bg-\[\#B5A8F8\] {
  --tw-bg-opacity: 1;
  background-color: rgb(181 168 248 / var(--tw-bg-opacity));
}

.bg-\[\#BEF8FC\] {
  --tw-bg-opacity: 1;
  background-color: rgb(190 248 252 / var(--tw-bg-opacity));
}

.bg-\[\#C1FF00\] {
  --tw-bg-opacity: 1;
  background-color: rgb(1 231 154 / var(--tw-bg-opacity));
}

.bg-\[\#E35523\] {
  --tw-bg-opacity: 1;
  background-color: rgb(227 85 35 / var(--tw-bg-opacity));
}

.bg-\[\#EAEDF0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(234 237 240 / var(--tw-bg-opacity));
}

.bg-\[\#EAEEE3\] {
  --tw-bg-opacity: 1;
  background-color: rgb(234 238 227 / var(--tw-bg-opacity));
}

.bg-\[\#F4F4F4\] {
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 244 / var(--tw-bg-opacity));
}

.bg-\[\#F5EFEB\] {
  --tw-bg-opacity: 1;
  background-color: rgb(245 239 235 / var(--tw-bg-opacity));
}

.bg-\[\#F5F3EA\] {
  --tw-bg-opacity: 1;
  background-color: rgb(245 243 234 / var(--tw-bg-opacity));
}

.bg-\[\#F6F9F0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(246 249 240 / var(--tw-bg-opacity));
}

.bg-\[\#FAF9F5\] {
  --tw-bg-opacity: 1;
  background-color: var(--bg-primary-color-lighter);
}

.bg-\[\#FAFAFA\] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.bg-\[\#FC6ADC\] {
  --tw-bg-opacity: 1;
  background-color: rgb(252 106 220 / var(--tw-bg-opacity));
}

.bg-\[\#FCEDCF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(252 237 207 / var(--tw-bg-opacity));
}

.bg-\[\#FDFBF9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(253 251 249 / var(--tw-bg-opacity));
}

.bg-\[\#FDFBF9\]\/10 {
  background-color: rgb(253 251 249 / 0.1);
}

.bg-\[\#FEF7E6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(254 247 230 / var(--tw-bg-opacity));
}

.bg-\[\#FFA767\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 167 103 / var(--tw-bg-opacity));
}

.bg-\[\#FFD372\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 211 114 / var(--tw-bg-opacity));
}

.bg-\[\#FFED88\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 237 136 / var(--tw-bg-opacity));
}

.bg-\[\#FFFCEF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 252 239 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: transparent;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-white\/10 {
  background-color: rgb(255 255 255 / 0.1);
}

.bg-white\/5 {
  background-color: rgb(255 255 255 / 0.05);
}

.bg-opacity-5 {
  --tw-bg-opacity: 0.05;
}

.bg-\[url\(\'\.\.\/img\/elements\/home-4-divider-shape-1\.svg\'\)\] {
  background-image: url('../img/elements/home-4-divider-shape-1.svg');
}

.bg-\[url\(\'\.\.\/img\/elements\/home-4-divider-shape-3\.svg\'\)\] {
  background-image: url('../img/elements/home-4-divider-shape-3.svg');
}

.bg-\[url\(\'\.\.\/img\/elements\/home-4-divider-shape-4\.svg\'\)\] {
  background-image: url('../img/elements/home-4-divider-shape-4.svg');
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.from-ColorBlack\/0 {
  --tw-gradient-from: rgb(10 16 47 / 0) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(10 16 47 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-ColorBlack {
  --tw-gradient-to: #0A102F var(--tw-gradient-to-position);
}

.bg-contain {
  background-size: contain;
}

.bg-cover {
  background-size: cover;
}

.bg-\[center_bottom\] {
  background-position: center bottom;
}

.bg-\[center_top\] {
  background-position: center top;
}

.bg-repeat-x {
  background-repeat: repeat-x;
}

.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.object-top {
  -o-object-position: top;
  object-position: top;
}

.p-0 {
  padding: 0px;
}

.p-10 {
  padding: 2.5rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-7 {
  padding: 1.75rem;
}

.p-8 {
  padding: 2rem;
}

.p-\[30px\] {
  padding: 30px;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-\[18\.5px\] {
  padding-left: 18.5px;
  padding-right: 18.5px;
}

.px-\[26px\] {
  padding-left: 26px;
  padding-right: 26px;
}

.px-\[30px\] {
  padding-left: 30px;
  padding-right: 30px;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-\[15px\] {
  padding-top: 15px;
  padding-bottom: 15px;
}

.py-\[18px\] {
  padding-top: 18px;
  padding-bottom: 18px;
}

.py-\[30px\] {
  padding-top: 30px;
  padding-bottom: 30px;
}

.py-\[5\.5px\] {
  padding-top: 5.5px;
  padding-bottom: 5.5px;
}

.py-\[60px\] {
  padding-top: 60px;
  padding-bottom: 60px;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-\[10px\] {
  padding-bottom: 10px;
}

.pb-\[14px\] {
  padding-bottom: 14px;
}

.pb-\[30px\] {
  padding-bottom: 30px;
}

.pb-\[60px\] {
  padding-bottom: 60px;
}

.pl-16 {
  padding-left: 4rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-\[10px\] {
  padding-left: 10px;
}

.pl-\[34px\] {
  padding-left: 34px;
}

.pr-20 {
  padding-right: 5rem;
}

.pr-40 {
  padding-right: 10rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pr-\[30px\] {
  padding-right: 30px;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-28 {
  padding-top: 7rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-\[100px\] {
  padding-top: 100px;
}

.pt-\[105px\] {
  padding-top: 105px;
}

.pt-\[14px\] {
  padding-top: 14px;
}

.pt-\[60px\] {
  padding-top: 60px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}
.text-primary{
  color: var(--primary-color) !important;
}
.font-Cabin {
  font-family: Cabin, sans-serif;
}

.font-ClashDisplay {
  font-family: Clash Display, sans-serif;
}

.font-DmSans {
  font-family: DM Sans, sans-serif;
}

.font-GeneralSans {
  font-family: General Sans, sans-serif;
}

.font-Kanit {
  font-family: Kanit, sans-serif;
}

.font-Outfit {
  font-family: Outfit, sans-serif;
}

.font-PlusJakartaSans {
  font-family: Public Sans, sans-serif;
}

.font-PublicSans {
  font-family: Public Sans, sans-serif;
}

.font-Sora {
  font-family: Sora, sans-serif;
}

.font-Syne {
  font-family: Syne, sans-serif;
}

.font-body {
  font-family: Inter, sans-serif;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-\[22px\] {
  font-size: 22px;
}

.text-\[26px\] {
  font-size: 26px;
}

.text-\[28px\] {
  font-size: 28px;
}

.text-\[30px\] {
  font-size: 30px;
}

.text-\[32px\] {
  font-size: 32px;
}

.text-\[40px\] {
  font-size: 40px;
}

.text-\[45px\] {
  font-size: 45px;
}

.text-\[48px\] {
  font-size: 48px;
}

.text-\[50px\] {
  font-size: 50px;
}

.text-\[62px\] {
  font-size: 62px;
}

.text-\[66px\] {
  font-size: 66px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-\[0\.9\] {
  line-height: 0.9;
}

.leading-\[0\] {
  line-height: 0;
}

.leading-\[1\.04\] {
  line-height: 1.04;
}

.leading-\[1\.05\] {
  line-height: 1.05;
}

.leading-\[1\.06\] {
  line-height: 1.06;
}

.leading-\[1\.07\] {
  line-height: 1.07;
}

.leading-\[1\.11\] {
  line-height: 1.11;
}

.leading-\[1\.12\] {
  line-height: 1.12;
}

.leading-\[1\.13\] {
  line-height: 1.13;
}

.leading-\[1\.14\] {
  line-height: 1.14;
}

.leading-\[1\.15\] {
  line-height: 1.15;
}

.leading-\[1\.16\] {
  line-height: 1.16;
}

.leading-\[1\.1\] {
  line-height: 1.1;
}

.leading-\[1\.23\] {
  line-height: 1.23;
}

.leading-\[1\.25\] {
  line-height: 1.25;
}

.leading-\[1\.28\] {
  line-height: 1.28;
}

.leading-\[1\.2\] {
  line-height: 1.2;
}

.leading-\[1\.33\] {
  line-height: 1.33;
}

.leading-\[1\.35\] {
  line-height: 1.35;
}

.leading-\[1\.3\] {
  line-height: 1.3;
}

.leading-\[1\.42\] {
  line-height: 1.42;
}

.leading-\[1\.44\] {
  line-height: 1.44;
}

.leading-\[1\.4\] {
  line-height: 1.4;
}

.leading-\[1\.5\] {
  line-height: 1.5;
}

.leading-\[1\.6\] {
  line-height: 1.6;
}

.leading-\[2\.85\] {
  line-height: 2.85;
}

.leading-none {
  line-height: 1;
}

.-tracking-\[0\.5\] {
  letter-spacing: -0.5;
}

.-tracking-\[0\.5px\] {
  letter-spacing: -0.5px;
}

.-tracking-\[1\] {
  letter-spacing: -1;
}

.-tracking-\[1px\] {
  letter-spacing: -1px;
}

.-tracking-\[2px\] {
  letter-spacing: -2px;
}

.-tracking-\[3px\] {
  letter-spacing: -3px;
}

.tracking-\[-0\.5px\] {
  letter-spacing: -0.5px;
}

.tracking-\[1px\] {
  letter-spacing: 1px;
}

.tracking-tight {
  letter-spacing: -0.025em;
}

.tracking-tighter {
  letter-spacing: -0.05em;
}

.tracking-widest {
  letter-spacing: 0.1em;
}

.text-ColorAtomicTangerine {
  --tw-text-opacity: 1;
  color: rgb(var(--primary-color-rgb) / var(--tw-text-opacity));
}

.text-ColorBlack {
  --tw-text-opacity: 1;
  color: rgb(10 16 47 / var(--tw-text-opacity));
}

.text-ColorBlack\/10 {
  color: rgb(10 16 47 / 0.1);
}

.text-ColorBlack\/60 {
  color: rgb(10 16 47 / 0.6);
}

.text-ColorBlack\/70 {
  color: rgb(10 16 47 / 0.7);
}

.text-ColorBlack\/80 {
  color: rgb(10 16 47 / 0.8);
}

.text-ColorBlue {
  --tw-text-opacity: 1;
  color: rgb(var(--primary-color-rgb) / var(--tw-text-opacity));
}

.text-ColorBlue\/80 {
  color: rgb(var(--primary-color-rgb) / 0.8);
}

.text-ColorCaribbeanGreen {
  --tw-text-opacity: 1;
  color: rgb(21 207 146 / var(--tw-text-opacity));
}

.text-ColorCorn {
  --tw-text-opacity: 1;
  color: rgb(244 185 5 / var(--tw-text-opacity));
}

.text-ColorDark {
  --tw-text-opacity: 1;
  color: rgb(25 25 25 / var(--tw-text-opacity));
}

.text-ColorDark\/80 {
  color: rgb(25 25 25 / 0.8);
}

.text-ColorDenimDarkBlue {
  --tw-text-opacity: 1;
  color: rgb(var(--primary-color-rgb) / var(--tw-text-opacity));
}

.text-ColorEggSour {
  --tw-text-opacity: 1;
  color: rgb(255 244 217 / var(--tw-text-opacity));
}

.text-ColorEggSour\/80 {
  color: rgb(255 244 217 / 0.8);
}

.text-ColorHoneySuckle {
  --tw-text-opacity: 1;
  color: rgb(233 255 151 / var(--tw-text-opacity));
}

.text-ColorLunarGreen {
  --tw-text-opacity: 1;
  color: rgb(50 68 56 / var(--tw-text-opacity));
}

.text-ColorMidnightMoss {
  --tw-text-opacity: 1;
  color: rgb(1 13 9 / var(--tw-text-opacity));
}

.text-ColorMidnightMoss\/80 {
  color: rgb(1 13 9 / 0.8);
}

.text-ColorOil {
  --tw-text-opacity: 1;
  color: rgb(34 31 26 / var(--tw-text-opacity));
}

.text-ColorOil\/80 {
  color: rgb(34 31 26 / 0.8);
}

.text-ColorPaleGold {
  --tw-text-opacity: 1;
  color: rgb(255 216 116 / var(--tw-text-opacity));
}

.text-ColorPurple {
  --tw-text-opacity: 1;
  color: rgb(var(--secondary-color-rgb) / var(--tw-text-opacity));
  /* color: rgb(6, 116, 79); */
}

.text-ColorYellow {
  --tw-text-opacity: 1;
  color: rgb(255 201 0 / var(--tw-text-opacity));
}

.text-\[\#0000FF\] {
  --tw-text-opacity: 1;
  color: rgb(0 0 255 / var(--tw-text-opacity));
}

.text-\[\#00B67A\] {
  --tw-text-opacity: 1;
  color: rgb(0 182 122 / var(--tw-text-opacity));
}

.text-\[\#00F\] {
  --tw-text-opacity: 1;
  color: rgb(0 0 255 / var(--tw-text-opacity));
}

.text-\[\#010D09\] {
  --tw-text-opacity: 1;
  color: rgb(1 13 9 / var(--tw-text-opacity));
}

.text-\[\#0E0E0E\] {
  --tw-text-opacity: 1;
  color: rgb(14 14 14 / var(--tw-text-opacity));
}

.text-\[\#0F1642\] {
  --tw-text-opacity: 1;
  color: rgb(15 22 66 / var(--tw-text-opacity));
}

.text-\[\#0F1642\]\/80 {
  color: rgb(15 22 66 / 0.8);
}

.text-\[\#1212121\] {
  color: #121212;
}

.text-\[\#121212\] {
  --tw-text-opacity: 1;
  color: rgb(18 18 18 / var(--tw-text-opacity));
}

.text-\[\#1B1C1D\] {
  --tw-text-opacity: 1;
  color: rgb(27 28 29 / var(--tw-text-opacity));
}

.text-\[\#2B13B9\] {
  --tw-text-opacity: 1;
  color: rgb(43 19 185 / var(--tw-text-opacity));
}

.text-\[\#2C2C2C\] {
  --tw-text-opacity: 1;
  color: rgb(44 44 44 / var(--tw-text-opacity));
}

.text-\[\#2c2c2c\] {
  --tw-text-opacity: 1;
  color: rgb(44 44 44 / var(--tw-text-opacity));
}

.text-\[\#48C96C\] {
  --tw-text-opacity: 1;
  color: rgb(72 201 108 / var(--tw-text-opacity));
}

.text-\[\#6B6F7B\] {
  --tw-text-opacity: 1;
  color: rgb(107 111 123 / var(--tw-text-opacity));
}

.text-\[\#7F8995\] {
  --tw-text-opacity: 1;
  color: rgb(127 137 149 / var(--tw-text-opacity));
}

.text-\[\#9B51E0\] {
  --tw-text-opacity: 1;
  color: rgb(155 81 224 / var(--tw-text-opacity));
}

.text-\[\#BEF8FC\] {
  --tw-text-opacity: 1;
  color: rgb(190 248 252 / var(--tw-text-opacity));
}

.text-\[\#E35523\] {
  --tw-text-opacity: 1;
  color: rgb(227 85 35 / var(--tw-text-opacity));
}

.text-\[\#F59E0B\] {
  --tw-text-opacity: 1;
  color: rgb(245 158 11 / var(--tw-text-opacity));
}

.text-\[\#F6F9F0\] {
  --tw-text-opacity: 1;
  color: rgb(246 249 240 / var(--tw-text-opacity));
}

.text-\[\#F6F9F0\]\/80 {
  color: rgb(246 249 240 / 0.8);
}

.text-\[\#FC6ADC\] {
  --tw-text-opacity: 1;
  color: rgb(252 106 220 / var(--tw-text-opacity));
}

.text-\[\#FDFBF9\] {
  --tw-text-opacity: 1;
  color: rgb(253 251 249 / var(--tw-text-opacity));
}

.text-\[\#FDFBF9\]\/60 {
  color: rgb(253 251 249 / 0.6);
}

.text-\[\#FDFBF9\]\/80 {
  color: rgb(253 251 249 / 0.8);
}

.text-\[\#FEF7E6\] {
  --tw-text-opacity: 1;
  color: rgb(254 247 230 / var(--tw-text-opacity));
}

.text-\[\#FFA767\] {
  --tw-text-opacity: 1;
  color: rgb(255 167 103 / var(--tw-text-opacity));
}

.text-\[\#FFC947\] {
  --tw-text-opacity: 1;
  color: rgb(255 201 71 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-white\/70 {
  color: rgb(255 255 255 / 0.7);
}

.text-white\/80 {
  color: rgb(255 255 255 / 0.8);
}

.text-opacity-50 {
  --tw-text-opacity: 0.5;
}

.text-opacity-80 {
  --tw-text-opacity: 0.8;
}

.underline {
  text-decoration-line: underline;
}

.underline-offset-4 {
  text-underline-offset: 4px;
}

.underline-offset-\[10px\] {
  text-underline-offset: 10px;
}

.accent-ColorBlue {
  accent-color: #3147FF;
}

.opacity-0 {
  opacity: 0;
}

.opacity-10 {
  opacity: 0.1;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-80 {
  opacity: 0.8;
}

.shadow-\[0_4px_80px_0_rgba\(0\2c 0\2c 0\2c 0\.08\)\] {
  --tw-shadow: 0 4px 80px 0 rgba(0, 0, 0, 0.08);
  --tw-shadow-colored: 0 4px 80px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_4px_80px_0px_rgba\(0\2c 0\2c 0\2c 0\.06\)\] {
  --tw-shadow: 0 4px 80px 0px rgba(0, 0, 0, 0.06);
  --tw-shadow-colored: 0 4px 80px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.outline {
  outline-style: solid;
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.duration-150 {
  transition-duration: 150ms;
}

.duration-300 {
  transition-duration: 300ms;
}

.duration-500 {
  transition-duration: 500ms;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.ease-linear {
  transition-timing-function: linear;
}

@keyframes fill-up-initial {
  100% {
    opacity: 0;
    transform: translate3d(0, -105%, 0) scale3d(1, 2, 1);
  }
}

@keyframes fill-up-end {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0) scale3d(1, 2, 1);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

/* Main Wrapper */

.main-wrapper {
  overflow: hidden;
}

.container-default {
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 576px) {
  .container-default {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 768px) {
  .container-default {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

@media (min-width: 992px) {
  .container-default {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 1200px) {
  .container-default {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

@media (min-width: 1400px) {
  .container-default {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.container-custom {
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 576px) {
  .container-custom {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 768px) {
  .container-custom {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media (min-width: 992px) {
  .container-custom {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 1200px) {
  .container-custom {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

@media (min-width: 1400px) {
  .container-custom {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.has-container-custom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

@media (min-width: 992px) {
  .has-container-custom {
    justify-content: normal;
    text-align: left;
  }
}

/* Section Space */

.section-space {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

@media (min-width: 992px) {
  .section-space {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media (min-width: 1200px) {
  .section-space {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.section-space-bottom {
  padding-bottom: 5rem;
}

@media (min-width: 992px) {
  .section-space-bottom {
    padding-bottom: 100px;
  }
}

@media (min-width: 1200px) {
  .section-space-bottom {
    padding-bottom: 120px;
  }
}

/* Horizontal Line Separator */

.horizontal-line {
  margin-left: auto;
  margin-right: auto;
  height: 1px;
  width: 100%;
  max-width: 1300px;
  opacity: 0.1;
}

/* Breadcrumb */

.breadcrumb-wrapper {
  position: relative;
  z-index: 10;
  --tw-bg-opacity: 1;
  background-color: var(--bg-primary-color-lighter);
  padding-bottom: 50px;
  padding-top: 105px;
}

@media (min-width: 992px) {
  .breadcrumb-wrapper {
    padding-bottom: 70px;
    padding-top: 135px;
  }
}

@media (min-width: 1200px) {
  .breadcrumb-wrapper {
    padding-bottom: 120px;
    padding-top: 165px;
  }
}

.breadcrumb-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.breadcrumb-title {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;
  max-width: 500px;
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 800;
  line-height: 1.2;
  letter-spacing: -1px;
}

@media (min-width: 576px) {
  .breadcrumb-title {
    font-size: 3rem;
    line-height: 1;
  }
}

@media (min-width: 768px) {
  .breadcrumb-title {
    font-size: 3.75rem;
    line-height: 1;
  }
}

@media (min-width: 992px) {
  .breadcrumb-title {
    margin-bottom: 2.5rem;
    max-width: 768px;
  }
}

@media (min-width: 1200px) {
  .breadcrumb-title {
    max-width: 1076px;
    font-size: 70px;
  }
}

.breadcrumb-nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  -moz-column-gap: 2.75rem;
  column-gap: 2.75rem;
}

.breadcrumb-nav li {
  position: relative;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.breadcrumb-nav li::after {
  position: absolute;
  right: -2rem;
  top: 50%;
  height: 1.25rem;
  width: 1.25rem;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  background-image: url(../img/icons/icon-black-chevron-right-solid.svg);
  content: var(--tw-content);
  background-size: cover;
}

.breadcrumb-nav li:last-child {
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgb(var(--primary-color-rgb) / var(--tw-text-opacity));
}

.breadcrumb-nav li:last-child::after {
  content: var(--tw-content);
  background-image: none;
}

.breadcrumb-nav a {
  font-weight: 600;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.breadcrumb-nav a:hover {
  --tw-text-opacity: 1;
  color: rgb(var(--primary-color-rgb) / var(--tw-text-opacity));
}

/* Accordion */

.accordion-body {
  height: 0px;
  --tw-translate-y: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  overflow: clip;
  opacity: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

.accordion-item.active .accordion-body {
  height: auto;
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: 1;
}

/* Accordion 1 */

.accordion-item.active .accordion-icon-1 span:last-child {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

.accordion-solid-shadow {
  position: relative;
  z-index: 10;
}

.accordion-solid-shadow::after {
  position: absolute;
  inset: 0px;
  z-index: -10;
  --tw-translate-x: 0px;
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-radius: 10px;
  --tw-bg-opacity: 1;
  background-color: rgb(10 16 47 / var(--tw-bg-opacity));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  content: var(--tw-content);
  transition-duration: 300ms;
}

.accordion-solid-shadow.active::after {
  --tw-translate-x: 10px;
  content: var(--tw-content);
  --tw-translate-y: 10px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

/* According - 2 */

.accordion-item.active .accordion-icon-2 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.accordion-item.active:has(.accordion-icon-2) {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.accordion-item.active:has(.accordion-icon-2):first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.accordion-item.active:has(.accordion-icon-2):last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* According - 3 */

.accordion-item.active .accordion-icon-3 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

/* According - 4 */

.accordion-item.active .accordion-icon-4 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

/* According - 5 */

.accordion-item .accordion-icon-5 {
  background-color: transparent;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

/* Accordion 6 */

.accordion-item .accordion-icon-6 span:first-child {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.accordion-item.active .accordion-icon-6 span:first-child {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 255 / var(--tw-bg-opacity));
}

.accordion-item.active .accordion-icon-6 span:last-child {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

.accordion-item.active .accordion-icon-5 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(1 13 9 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(21 207 146 / var(--tw-text-opacity));
}

.accordion-item.active:has(.accordion-icon-4):first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.accordion-item.active:has(.accordion-icon-4):last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.accordion-item.active:has(.accordion-icon-5) {
  --tw-bg-opacity: 1;
  background-color: rgb(255 252 239 / var(--tw-bg-opacity));
}

.rich-text h5 {
  margin-bottom: 1rem;
}

/* Tab */

.tab-text.active {
  --tw-text-opacity: 1;
  color: rgb(var(--primary-color-rgb) / var(--tw-text-opacity));
}

.process-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 50px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.process-tab.active {
  --tw-bg-opacity: 1;
  background-color: rgb(244 185 5 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(50 68 56 / var(--tw-text-opacity));
}

.process-tab img:first-child {
  opacity: 1;
}

.process-tab img:last-child {
  opacity: 0;
}

.process-tab.active img:first-child {
  opacity: 0;
}

.process-tab.active img:last-child {
  opacity: 1;
}

/* Hover Effects */

.hover-solid-shadow {
  position: relative;
  z-index: 10;
}

.hover-solid-shadow::after {
  position: absolute;
  inset: 0px;
  z-index: -10;
  border-radius: 10px;
  --tw-bg-opacity: 1;
  background-color: rgb(10 16 47 / var(--tw-bg-opacity));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  content: var(--tw-content);
  transition-duration: 300ms;
}

.hover-solid-shadow:hover::after {
  --tw-translate-x: 10px;
  content: var(--tw-content);
  --tw-translate-y: 10px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

/* Testimonial Slider */

.testimonial-slider-area-1 .swiper-pagination-bullet {
  margin-left: 6px !important;
  margin-right: 6px !important;
  height: 10px;
  width: 10px;
  background-color: rgb(25 25 25 / 0.8);
}

.testimonial-slider-area-1 .swiper-pagination-bullet-active {
  height: 10px;
  width: 10px;
  --tw-bg-opacity: 1;
  background-color: rgb(25 25 25 / var(--tw-bg-opacity));
}

.placeholder\:text-ColorBlack::-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgb(10 16 47 / var(--tw-text-opacity));
}

.placeholder\:text-ColorBlack::placeholder {
  --tw-text-opacity: 1;
  color: rgb(10 16 47 / var(--tw-text-opacity));
}

.placeholder\:text-ColorBlack\/50::-moz-placeholder {
  color: rgb(10 16 47 / 0.5);
}

.placeholder\:text-ColorBlack\/50::placeholder {
  color: rgb(10 16 47 / 0.5);
}

.placeholder\:text-ColorBlack\/80::-moz-placeholder {
  color: rgb(10 16 47 / 0.8);
}

.placeholder\:text-ColorBlack\/80::placeholder {
  color: rgb(10 16 47 / 0.8);
}

.placeholder\:text-black::-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.placeholder\:text-black::placeholder {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.after\:absolute::after {
  content: var(--tw-content);
  position: absolute;
}

.after\:inset-0::after {
  content: var(--tw-content);
  inset: 0px;
}

.after\:bottom-0::after {
  content: var(--tw-content);
  bottom: 0px;
}

.after\:left-0::after {
  content: var(--tw-content);
  left: 0px;
}

.after\:left-1\/2::after {
  content: var(--tw-content);
  left: 50%;
}

.after\:left-\[calc\(100\%\+_10px\)\]::after {
  content: var(--tw-content);
  left: calc(100% + 10px);
}

.after\:left-\[calc\(100\%\+_24px\)\]::after {
  content: var(--tw-content);
  left: calc(100% + 24px);
}

.after\:left-\[calc\(100\%\+_25px\)\]::after {
  content: var(--tw-content);
  left: calc(100% + 25px);
}

.after\:left-\[calc\(100\%\+_30px\)\]::after {
  content: var(--tw-content);
  left: calc(100% + 30px);
}

.after\:left-\[calc\(50\%\+_40px\)\]::after {
  content: var(--tw-content);
  left: calc(50% + 40px);
}

.after\:left-full::after {
  content: var(--tw-content);
  left: 100%;
}

.after\:right-0::after {
  content: var(--tw-content);
  right: 0px;
}

.after\:top-0::after {
  content: var(--tw-content);
  top: 0px;
}

.after\:top-1\/2::after {
  content: var(--tw-content);
  top: 50%;
}

.after\:top-10::after {
  content: var(--tw-content);
  top: 2.5rem;
}

.after\:-z-10::after {
  content: var(--tw-content);
  z-index: -10;
}

.after\:z-10::after {
  content: var(--tw-content);
  z-index: 10;
}

.after\:hidden::after {
  content: var(--tw-content);
  display: none;
}

.after\:h-10::after {
  content: var(--tw-content);
  height: 2.5rem;
}

.after\:h-20::after {
  content: var(--tw-content);
  height: 5rem;
}

.after\:h-4\/5::after {
  content: var(--tw-content);
  height: 80%;
}

.after\:h-\[100px\]::after {
  content: var(--tw-content);
  height: 100px;
}

.after\:h-\[120px\]::after {
  content: var(--tw-content);
  height: 120px;
}

.after\:h-\[2px\]::after {
  content: var(--tw-content);
  height: 2px;
}

.after\:h-\[6px\]::after {
  content: var(--tw-content);
  height: 6px;
}

.after\:h-full::after {
  content: var(--tw-content);
  height: 100%;
}

.after\:w-0::after {
  content: var(--tw-content);
  width: 0px;
}

.after\:w-0\.5::after {
  content: var(--tw-content);
  width: 0.125rem;
}

.after\:w-\[106\%\]::after {
  content: var(--tw-content);
  width: 106%;
}

.after\:w-\[2px\]::after {
  content: var(--tw-content);
  width: 2px;
}

.after\:w-\[6px\]::after {
  content: var(--tw-content);
  width: 6px;
}

.after\:w-full::after {
  content: var(--tw-content);
  width: 100%;
}

.after\:w-px::after {
  content: var(--tw-content);
  width: 1px;
}

.after\:-translate-x-1\/2::after {
  content: var(--tw-content);
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:-translate-y-1\/2::after {
  content: var(--tw-content);
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:translate-x-0::after {
  content: var(--tw-content);
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:translate-y-0::after {
  content: var(--tw-content);
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:scale-x-0::after {
  content: var(--tw-content);
  --tw-scale-x: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:rounded-\[10px\]::after {
  content: var(--tw-content);
  border-radius: 10px;
}

.after\:rounded-\[11px\]::after {
  content: var(--tw-content);
  border-radius: 11px;
}

.after\:rounded-\[50\%\]::after {
  content: var(--tw-content);
  border-radius: 50%;
}

.after\:rounded-\[5px\]::after {
  content: var(--tw-content);
  border-radius: 5px;
}

.after\:border::after {
  content: var(--tw-content);
  border-width: 1px;
}

.after\:border-dashed::after {
  content: var(--tw-content);
  border-style: dashed;
}

.after\:border-ColorBlack::after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(10 16 47 / var(--tw-border-opacity));
}

.after\:border-ColorOil::after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(34 31 26 / var(--tw-border-opacity));
}

.after\:bg-ColorAtomicTangerine::after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(var(--primary-color-rgb) / var(--tw-bg-opacity));
}

.after\:bg-ColorBlack::after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(10 16 47 / var(--tw-bg-opacity));
}

.after\:bg-ColorBlack\/10::after {
  content: var(--tw-content);
  background-color: rgb(10 16 47 / 0.1);
}

.after\:bg-ColorBlack\/40::after {
  content: var(--tw-content);
  background-color: rgb(10 16 47 / 0.4);
}

.after\:bg-ColorBlack\/80::after {
  content: var(--tw-content);
  background-color: rgb(10 16 47 / 0.8);
}

.after\:bg-ColorDark\/10::after {
  content: var(--tw-content);
  background-color: rgb(25 25 25 / 0.1);
}

.after\:bg-ColorOil::after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(34 31 26 / var(--tw-bg-opacity));
}

.after\:bg-ColorOil\/10::after {
  content: var(--tw-content);
  background-color: rgb(34 31 26 / 0.1);
}

.after\:bg-ColorPurple::after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(var(--secondary-color-rgb) / var(--tw-bg-opacity));
}

.after\:bg-\[\#BEF8FC\]::after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(190 248 252 / var(--tw-bg-opacity));
}

.after\:bg-\[\#F65510\]::after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(246 85 16 / var(--tw-bg-opacity));
}

.after\:bg-black::after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.after\:px-4::after {
  content: var(--tw-content);
  padding-left: 1rem;
  padding-right: 1rem;
}

.after\:transition-all::after {
  content: var(--tw-content);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.after\:duration-300::after {
  content: var(--tw-content);
  transition-duration: 300ms;
}

.after\:ease-in-out::after {
  content: var(--tw-content);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.after\:content-none::after {
  --tw-content: none;
  content: var(--tw-content);
}

.first\:-ml-0:first-child {
  margin-left: -0px;
}

.first\:pl-0:first-child {
  padding-left: 0px;
}

.first\:pt-0:first-child {
  padding-top: 0px;
}

.last\:mb-0:last-child {
  margin-bottom: 0px;
}

.last\:border-b-0:last-child {
  border-bottom-width: 0px;
}

.last\:border-none:last-child {
  border-style: none;
}

.last\:pb-0:last-child {
  padding-bottom: 0px;
}

.last\:pr-0:last-child {
  padding-right: 0px;
}

.last\:after\:hidden:last-child::after {
  content: var(--tw-content);
  display: none;
}

.last\:after\:content-none:last-child::after {
  --tw-content: none;
  content: var(--tw-content);
}

.even\:border-r-0:nth-child(even) {
  border-right-width: 0px;
}

.even\:after\:hidden:nth-child(even)::after {
  content: var(--tw-content);
  display: none;
}

.hover\:translate-x-2:hover {
  --tw-translate-x: 0.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:-rotate-3:hover {
  --tw-rotate: -3deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:border-ColorDenimDarkBlue:hover {
  --tw-border-opacity: 1;
  border-color: rgb(var(--primary-color-rgb) / var(--tw-border-opacity));
}

.hover\:border-ColorOffWhite:hover {
  --tw-border-opacity: 1;
  border-color: var(--bg-primary-color-lighter);
}

.hover\:border-\[\#E9FF97\]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(233 255 151 / var(--tw-border-opacity));
}

.hover\:border-white:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.hover\:bg-ColorAtomicTangerine:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--primary-color-rgb) / var(--tw-bg-opacity));
}

.hover\:bg-ColorBlack:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(10 16 47 / var(--tw-bg-opacity));
}

.hover\:bg-ColorBlue:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--primary-color-rgb) / var(--tw-bg-opacity));
}

.hover\:bg-ColorCaribbeanGreen:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(21 207 146 / var(--tw-bg-opacity));
}

.hover\:bg-ColorCorn:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(244 185 5 / var(--tw-bg-opacity));
}

.hover\:bg-ColorDark:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(25 25 25 / var(--tw-bg-opacity));
}

.hover\:bg-ColorDenimDarkBlue:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--primary-color-rgb) / var(--tw-bg-opacity));
}

.hover\:bg-ColorHoneySuckle:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(233 255 151 / var(--tw-bg-opacity));
}

.hover\:bg-ColorLime:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(1 231 154 / var(--tw-bg-opacity));
}

.hover\:bg-ColorMidnightMoss:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(1 13 9 / var(--tw-bg-opacity));
}

.hover\:bg-ColorOffWhite:hover {
  --tw-bg-opacity: 1;
  background-color: var(--bg-primary-color-lighter);
}

.hover\:bg-ColorPaleGold:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 216 116 / var(--tw-bg-opacity));
}

.hover\:bg-ColorViolet:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(75 42 173 / var(--tw-bg-opacity));
}

.hover\:bg-ColorYellow:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 201 0 / var(--tw-bg-opacity));
}

.hover\:bg-\[\#0000FF\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 255 / var(--tw-bg-opacity));
}

.hover\:bg-\[\#0E0E0E\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(14 14 14 / var(--tw-bg-opacity));
}

.hover\:bg-\[\#a6ff00\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(166 255 0 / var(--tw-bg-opacity));
}

.hover\:bg-black:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:bg-opacity-100:hover {
  --tw-bg-opacity: 1;
}

.hover\:text-ColorAtomicTangerine:hover {
  --tw-text-opacity: 1;
  color: rgb(var(--primary-color-rgb) / var(--tw-text-opacity));
}

.hover\:text-ColorBlue:hover {
  --tw-text-opacity: 1;
  color: rgb(var(--primary-color-rgb) / var(--tw-text-opacity));
}

.hover\:text-ColorBluePurple:hover {
  --tw-text-opacity: 1;
  color: rgb(121 73 246 / var(--tw-text-opacity));
}

.hover\:text-ColorCaribbeanGreen:hover {
  --tw-text-opacity: 1;
  color: rgb(21 207 146 / var(--tw-text-opacity));
}

.hover\:text-ColorDenimDarkBlue:hover {
  --tw-text-opacity: 1;
  color: rgb(var(--primary-color-rgb) / var(--tw-text-opacity));
}

.hover\:text-ColorEggSour:hover {
  --tw-text-opacity: 1;
  color: rgb(255 244 217 / var(--tw-text-opacity));
}

.hover\:text-ColorHoneySuckle:hover {
  --tw-text-opacity: 1;
  color: rgb(233 255 151 / var(--tw-text-opacity));
}

.hover\:text-ColorLime:hover {
  --tw-text-opacity: 1;
  color: rgb(1 231 154 / var(--tw-text-opacity));
}

.hover\:text-ColorLunarGreen:hover {
  --tw-text-opacity: 1;
  color: rgb(50 68 56 / var(--tw-text-opacity));
}

.hover\:text-ColorPaleGold:hover {
  --tw-text-opacity: 1;
  color: rgb(255 216 116 / var(--tw-text-opacity));
}

.hover\:text-ColorPurple:hover {
  --tw-text-opacity: 1;
  color: rgb(var(--secondary-color-rgb) / var(--tw-text-opacity));
}

.hover\:text-ColorViolet:hover {
  --tw-text-opacity: 1;
  color: rgb(75 42 173 / var(--tw-text-opacity));
}

.hover\:text-ColorYellow:hover {
  --tw-text-opacity: 1;
  color: rgb(255 201 0 / var(--tw-text-opacity));
}

.hover\:text-\[\#0000FF\]:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 255 / var(--tw-text-opacity));
}

.hover\:text-\[\#121212\]:hover {
  --tw-text-opacity: 1;
  color: rgb(18 18 18 / var(--tw-text-opacity));
}

.hover\:text-\[\#1B1C1D\]:hover {
  --tw-text-opacity: 1;
  color: rgb(27 28 29 / var(--tw-text-opacity));
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.hover\:text-red-600:hover {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:shadow-\[0_4px_60px_0_rgba\(10\2c 16\2c 47\2c 0\.06\)\]:hover {
  --tw-shadow: 0 4px 60px 0 rgba(10, 16, 47, 0.06);
  --tw-shadow-colored: 0 4px 60px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-\[0_4px_60px_rgba\(10\2c 16\2c 47\2c 0\.08\)\]:hover {
  --tw-shadow: 0 4px 60px rgba(10, 16, 47, 0.08);
  --tw-shadow-colored: 0 4px 60px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-\[0_4px_80px_rgba\(0\2c 0\2c 0\2c 0\.06\)\]:hover {
  --tw-shadow: 0 4px 80px rgba(0, 0, 0, 0.06);
  --tw-shadow-colored: 0 4px 80px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:after\:translate-x-\[10px\]:hover::after {
  content: var(--tw-content);
  --tw-translate-x: 10px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:after\:translate-y-\[10px\]:hover::after {
  content: var(--tw-content);
  --tw-translate-y: 10px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.focus\:border-ColorBlue:focus {
  --tw-border-opacity: 1;
  border-color: rgb(var(--primary-color-rgb) / var(--tw-border-opacity));
}

.focus\:border-ColorCaribbeanGreen:focus {
  --tw-border-opacity: 1;
  border-color: rgb(21 207 146 / var(--tw-border-opacity));
}

.focus\:border-\[\#0000FF\]:focus {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 255 / var(--tw-border-opacity));
}

.focus\:text-black:focus {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.active\:bg-ColorBlue:active {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--primary-color-rgb) / var(--tw-bg-opacity));
}

.active\:text-red-600:active {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.group:hover .group-hover\:-translate-y-5 {
  --tw-translate-y: -1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:-translate-y-full {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:translate-x-2 {
  --tw-translate-x: 0.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:scale-105 {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:scale-110 {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:scale-x-100 {
  --tw-scale-x: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:border-ColorAtomicTangerine {
  --tw-border-opacity: 1;
  border-color: rgb(var(--primary-color-rgb) / var(--tw-border-opacity));
}

.group:hover .group-hover\:border-\[\#F65510\] {
  --tw-border-opacity: 1;
  border-color: rgb(246 85 16 / var(--tw-border-opacity));
}

.group:hover .group-hover\:bg-ColorAtomicTangerine {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--primary-color-rgb) / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-\[\#F65510\] {
  --tw-bg-opacity: 1;
  background-color: rgb(246 85 16 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-\[\#F6F9F0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(246 249 240 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:text-ColorAtomicTangerine {
  --tw-text-opacity: 1;
  color: rgb(var(--primary-color-rgb) / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-ColorBlue {
  --tw-text-opacity: 1;
  color: rgb(var(--primary-color-rgb) / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-ColorPurple {
  --tw-text-opacity: 1;
  color: rgb(var(--secondary-color-rgb) / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-ColorViolet {
  --tw-text-opacity: 1;
  color: rgb(75 42 173 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-\[\#F6F9F0\] {
  --tw-text-opacity: 1;
  color: rgb(246 249 240 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.group:hover .group-hover\:opacity-0 {
  opacity: 0;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group:hover .group-hover\:after\:scale-x-100::after {
  content: var(--tw-content);
  --tw-scale-x: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.has-\[\.accordion-icon-4\]\:rounded-none:has(.accordion-icon-4) {
  border-radius: 0px;
}

.has-\[\.accordion-header\]\:pt-6:has(.accordion-header) {
  padding-top: 1.5rem;
}

.first\:has-\[\.accordion-header\]\:pt-0:has(.accordion-header):first-child {
  padding-top: 0px;
}

@media (min-width: 576px) {
  .sm\:absolute {
    position: absolute;
  }

  .sm\:mt-0 {
    margin-top: 0px;
  }

  .sm\:block {
    display: block;
  }

  .sm\:inline-block {
    display: inline-block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:h-\[60px\] {
    height: 60px;
  }

  .sm\:w-16 {
    width: 4rem;
  }

  .sm\:w-\[150px\] {
    width: 150px;
  }

  .sm\:w-\[480px\] {
    width: 480px;
  }

  .sm\:w-\[80\%\] {
    width: 80%;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:max-w-\[100\%\] {
    max-width: 100%;
  }

  .sm\:max-w-\[350px\] {
    max-width: 350px;
  }

  .sm\:max-w-\[500px\] {
    max-width: 500px;
  }

  .sm\:max-w-\[636px\] {
    max-width: 636px;
  }

  .sm\:max-w-\[70\%\] {
    max-width: 70%;
  }

  .sm\:max-w-\[80\%\] {
    max-width: 80%;
  }

  .sm\:flex-1 {
    flex: 1 1 0%;
  }

  .sm\:translate-y-10 {
    --tw-translate-y: 2.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-cols-\[1fr_minmax\(0\2c _0\.4fr\)\] {
    grid-template-columns: 1fr minmax(0, 0.4fr);
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:justify-normal {
    justify-content: normal;
  }

  .sm\:gap-0 {
    gap: 0px;
  }

  .sm\:gap-0\.5 {
    gap: 0.125rem;
  }

  .sm\:gap-20 {
    gap: 5rem;
  }

  .sm\:gap-6 {
    gap: 1.5rem;
  }

  .sm\:gap-\[50px\] {
    gap: 50px;
  }

  .sm\:gap-y-8 {
    row-gap: 2rem;
  }

  .sm\:bg-ColorOffWhite {
    --tw-bg-opacity: 1;
    background-color: var(--bg-primary-color-lighter);
  }

  .sm\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .sm\:p-10 {
    padding: 2.5rem;
  }

  .sm\:p-\[30px\] {
    padding: 30px;
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .sm\:pl-32 {
    padding-left: 8rem;
  }

  .sm\:pt-10 {
    padding-top: 2.5rem;
  }

  .sm\:pt-\[145px\] {
    padding-top: 145px;
  }

  .sm\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .sm\:after\:block::after {
    content: var(--tw-content);
    display: block;
  }

  .sm\:after\:content-\[\'_\:\'\]::after {
    --tw-content: ' :';
    content: var(--tw-content);
  }
}

@media (min-width: 768px) {
  .md\:order-1 {
    order: 1;
  }

  .md\:order-2 {
    order: 2;
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .md\:my-\[30px\] {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .md\:mb-16 {
    margin-bottom: 4rem;
  }

  .md\:mt-20 {
    margin-top: 5rem;
  }

  .md\:block {
    display: block;
  }

  .md\:inline-block {
    display: inline-block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:h-80 {
    height: 20rem;
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-12 {
    width: 3rem;
  }

  .md\:w-20 {
    width: 5rem;
  }

  .md\:w-24 {
    width: 6rem;
  }

  .md\:w-\[556px\] {
    width: 556px;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:max-w-\[50\%\] {
    max-width: 50%;
  }

  .md\:max-w-\[500px\] {
    max-width: 500px;
  }

  .md\:max-w-\[60\%\] {
    max-width: 60%;
  }

  .md\:max-w-\[70\%\] {
    max-width: 70%;
  }

  .md\:max-w-\[80\%\] {
    max-width: 80%;
  }

  .md\:max-w-full {
    max-width: 100%;
  }

  .md\:-rotate-\[5deg\] {
    --tw-rotate: -5deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:rotate-\[5deg\] {
    --tw-rotate: 5deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:columns-2 {
    -moz-columns: 2;
    columns: 2;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:grid-cols-\[0\.55fr_minmax\(0\2c _1fr\)\] {
    grid-template-columns: 0.55fr minmax(0, 1fr);
  }

  .md\:grid-cols-\[0\.7fr_minmax\(0\2c _1fr\)\] {
    grid-template-columns: 0.7fr minmax(0, 1fr);
  }

  .md\:grid-cols-\[0\.8fr_minmax\(0\2c _1fr\)\] {
    grid-template-columns: 0.8fr minmax(0, 1fr);
  }

  .md\:grid-cols-\[1fr_minmax\(0\2c 0\.5fr\)\] {
    grid-template-columns: 1fr minmax(0, 0.5fr);
  }

  .md\:grid-cols-\[1fr_minmax\(0\2c _0\.6fr\)\] {
    grid-template-columns: 1fr minmax(0, 0.6fr);
  }

  .md\:grid-cols-\[1fr_minmax\(0\2c _0\.7fr\)\] {
    grid-template-columns: 1fr minmax(0, 0.7fr);
  }

  .md\:grid-cols-\[1fr_minmax\(0\2c _0\.85fr\)\] {
    grid-template-columns: 1fr minmax(0, 0.85fr);
  }

  .md\:grid-cols-\[1fr_minmax\(0\2c _0\.8fr\)\] {
    grid-template-columns: 1fr minmax(0, 0.8fr);
  }

  .md\:grid-cols-\[1fr_minmax\(0\2c _0\.9fr\)\] {
    grid-template-columns: 1fr minmax(0, 0.9fr);
  }

  .md\:grid-cols-\[minmax\(0\2c 0\.5fr\)_1fr\] {
    grid-template-columns: minmax(0, 0.5fr) 1fr;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-normal {
    justify-content: normal;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:gap-10 {
    gap: 2.5rem;
  }

  .md\:gap-16 {
    gap: 4rem;
  }

  .md\:gap-6 {
    gap: 1.5rem;
  }

  .md\:gap-\[60px\] {
    gap: 60px;
  }

  .md\:gap-x-\[100px\] {
    -moz-column-gap: 100px;
    column-gap: 100px;
  }

  .md\:divide-x> :not([hidden])~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:self-end {
    align-self: flex-end;
  }

  .md\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .md\:rounded-bl-\[50px\] {
    border-bottom-left-radius: 50px;
  }

  .md\:rounded-br-\[50px\] {
    border-bottom-right-radius: 50px;
  }

  .md\:p-10 {
    padding: 2.5rem;
  }

  .md\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .md\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .md\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .md\:py-\[50px\] {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .md\:py-\[60px\] {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .md\:pb-20 {
    padding-bottom: 5rem;
  }

  .md\:pl-10 {
    padding-left: 2.5rem;
  }

  .md\:pr-10 {
    padding-right: 2.5rem;
  }

  .md\:pr-12 {
    padding-right: 3rem;
  }

  .md\:pt-36 {
    padding-top: 9rem;
  }

  .md\:pt-\[120px\] {
    padding-top: 120px;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .md\:text-\[60px\] {
    font-size: 60px;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:after\:left-\[calc\(100\%\+_40px\)\]::after {
    content: var(--tw-content);
    left: calc(100% + 40px);
  }

  .md\:last\:border-none:last-child {
    border-style: none;
  }
}

@media (min-width: 992px) {
  .lg\:absolute {
    position: absolute;
  }

  .lg\:-left-28 {
    left: -7rem;
  }

  .lg\:-top-\[70px\] {
    top: -70px;
  }

  .lg\:left-\[510px\] {
    left: 510px;
  }

  .lg\:right-10 {
    right: 2.5rem;
  }

  .lg\:order-1 {
    order: 1;
  }

  .lg\:order-2 {
    order: 2;
  }

  .lg\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .lg\:my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .lg\:my-\[50px\] {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .lg\:my-\[60xp\] {
    margin-top: 60xp;
    margin-bottom: 60xp;
  }

  .lg\:-mb-40 {
    margin-bottom: -10rem;
  }

  .lg\:-mt-\[50px\] {
    margin-top: -50px;
  }

  .lg\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .lg\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .lg\:mb-20 {
    margin-bottom: 5rem;
  }

  .lg\:mb-\[30px\] {
    margin-bottom: 30px;
  }

  .lg\:mb-\[50px\] {
    margin-bottom: 50px;
  }

  .lg\:mb-\[60px\] {
    margin-bottom: 60px;
  }

  .lg\:mb-\[60xp\] {
    margin-bottom: 60xp;
  }

  .lg\:ml-auto {
    margin-left: auto;
  }

  .lg\:mr-0 {
    margin-right: 0px;
  }

  .lg\:mt-0 {
    margin-top: 0px;
  }

  .lg\:mt-20 {
    margin-top: 5rem;
  }

  .lg\:mt-4 {
    margin-top: 1rem;
  }

  .lg\:mt-5 {
    margin-top: 1.25rem;
  }

  .lg\:mt-\[100px\] {
    margin-top: 100px;
  }

  .lg\:mt-\[50px\] {
    margin-top: 50px;
  }

  .lg\:mt-\[60px\] {
    margin-top: 60px;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline-block {
    display: inline-block;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-10 {
    height: 2.5rem;
  }

  .lg\:h-20 {
    height: 5rem;
  }

  .lg\:h-60 {
    height: 15rem;
  }

  .lg\:h-\[390px\] {
    height: 390px;
  }

  .lg\:h-\[431px\] {
    height: 431px;
  }

  .lg\:h-\[50px\] {
    height: 50px;
  }

  .lg\:h-\[600px\] {
    height: 600px;
  }

  .lg\:h-\[60px\] {
    height: 60px;
  }

  .lg\:h-\[70px\] {
    height: 70px;
  }

  .lg\:h-auto {
    height: auto;
  }

  .lg\:w-10 {
    width: 2.5rem;
  }

  .lg\:w-16 {
    width: 4rem;
  }

  .lg\:w-20 {
    width: 5rem;
  }

  .lg\:w-24 {
    width: 6rem;
  }

  .lg\:w-\[126px\] {
    width: 126px;
  }

  .lg\:w-\[416px\] {
    width: 416px;
  }

  .lg\:w-\[50px\] {
    width: 50px;
  }

  .lg\:w-\[98px\] {
    width: 98px;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:max-w-2xl {
    max-width: 42rem;
  }

  .lg\:max-w-\[320px\] {
    max-width: 320px;
  }

  .lg\:max-w-\[381px\] {
    max-width: 381px;
  }

  .lg\:max-w-\[416px\] {
    max-width: 416px;
  }

  .lg\:max-w-\[460px\] {
    max-width: 460px;
  }

  .lg\:max-w-\[550px\] {
    max-width: 550px;
  }

  .lg\:max-w-\[62\%\] {
    max-width: 62%;
  }

  .lg\:max-w-\[670px\] {
    max-width: 670px;
  }

  .lg\:max-w-\[675px\] {
    max-width: 675px;
  }

  .lg\:max-w-\[70\%\] {
    max-width: 70%;
  }

  .lg\:max-w-full {
    max-width: 100%;
  }

  .lg\:max-w-md {
    max-width: 28rem;
  }

  .lg\:max-w-none {
    max-width: none;
  }

  .lg\:columns-3 {
    -moz-columns: 3;
    columns: 3;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg\:grid-cols-\[0\.55fr_minmax\(0\2c _1fr\)\] {
    grid-template-columns: 0.55fr minmax(0, 1fr);
  }

  .lg\:grid-cols-\[0\.75fr_minmax\(0\2c _1fr\)\] {
    grid-template-columns: 0.75fr minmax(0, 1fr);
  }

  .lg\:grid-cols-\[0\.7fr_minmax\(0\2c _1fr\)\] {
    grid-template-columns: 0.7fr minmax(0, 1fr);
  }

  .lg\:grid-cols-\[0\.85fr_1fr\] {
    grid-template-columns: 0.85fr 1fr;
  }

  .lg\:grid-cols-\[0\.8fr_1fr\] {
    grid-template-columns: 0.8fr 1fr;
  }

  .lg\:grid-cols-\[0\.8fr_minmax\(0\2c _1fr\)\] {
    grid-template-columns: 0.8fr minmax(0, 1fr);
  }

  .lg\:grid-cols-\[0\.9fr_minmax\(0\2c _1fr\)\] {
    grid-template-columns: 0.9fr minmax(0, 1fr);
  }

  .lg\:grid-cols-\[1fr\2c minmax\(0\2c _0\.6fr\)\] {
    grid-template-columns: 1fr minmax(0, 0.6fr);
  }

  .lg\:grid-cols-\[1fr\2c minmax\(0\2c _0\.8fr\)\] {
    grid-template-columns: 1fr minmax(0, 0.8fr);
  }

  .lg\:grid-cols-\[1fr\2c minmax\(416px\2c _0\.45fr\)\] {
    grid-template-columns: 1fr minmax(416px, 0.45fr);
  }

  .lg\:grid-cols-\[1fr_0\.8fr\] {
    grid-template-columns: 1fr 0.8fr;
  }

  .lg\:grid-cols-\[1fr_minmax\(0\2c _0\.2fr\)\] {
    grid-template-columns: 1fr minmax(0, 0.2fr);
  }

  .lg\:grid-cols-\[1fr_minmax\(0\2c _0\.5fr\)\] {
    grid-template-columns: 1fr minmax(0, 0.5fr);
  }

  .lg\:grid-cols-\[1fr_minmax\(0\2c _0\.6fr\)\] {
    grid-template-columns: 1fr minmax(0, 0.6fr);
  }

  .lg\:grid-cols-\[1fr_minmax\(0\2c _0\.75fr\)\] {
    grid-template-columns: 1fr minmax(0, 0.75fr);
  }

  .lg\:grid-cols-\[1fr_minmax\(0\2c _0\.7fr\)\] {
    grid-template-columns: 1fr minmax(0, 0.7fr);
  }

  .lg\:grid-cols-\[1fr_minmax\(0\2c _0\.8fr\)\] {
    grid-template-columns: 1fr minmax(0, 0.8fr);
  }

  .lg\:grid-cols-\[1fr_minmax\(0\2c _0\.9fr\)\] {
    grid-template-columns: 1fr minmax(0, 0.9fr);
  }

  .lg\:grid-cols-\[1fr_repeat\(3\2c _auto\)\] {
    grid-template-columns: 1fr repeat(3, auto);
  }

  .lg\:grid-cols-\[minmax\(0\2c 0\.4fr\)_1fr\] {
    grid-template-columns: minmax(0, 0.4fr) 1fr;
  }

  .lg\:grid-cols-\[minmax\(0\2c _0\.7fr\)\2c _1fr\] {
    grid-template-columns: minmax(0, 0.7fr) 1fr;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:justify-normal {
    justify-content: normal;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:gap-10 {
    gap: 2.5rem;
  }

  .lg\:gap-14 {
    gap: 3.5rem;
  }

  .lg\:gap-16 {
    gap: 4rem;
  }

  .lg\:gap-20 {
    gap: 5rem;
  }

  .lg\:gap-24 {
    gap: 6rem;
  }

  .lg\:gap-28 {
    gap: 7rem;
  }

  .lg\:gap-32 {
    gap: 8rem;
  }

  .lg\:gap-8 {
    gap: 2rem;
  }

  .lg\:gap-\[118px\] {
    gap: 118px;
  }

  .lg\:gap-\[50px\] {
    gap: 50px;
  }

  .lg\:gap-\[60px\] {
    gap: 60px;
  }

  .lg\:gap-\[66px\] {
    gap: 66px;
  }

  .lg\:gap-\[74px\] {
    gap: 74px;
  }

  .lg\:gap-\[90px\] {
    gap: 90px;
  }

  .lg\:gap-x-10 {
    -moz-column-gap: 2.5rem;
    column-gap: 2.5rem;
  }

  .lg\:gap-x-16 {
    -moz-column-gap: 4rem;
    column-gap: 4rem;
  }

  .lg\:gap-x-20 {
    -moz-column-gap: 5rem;
    column-gap: 5rem;
  }

  .lg\:gap-y-10 {
    row-gap: 2.5rem;
  }

  .lg\:gap-y-14 {
    row-gap: 3.5rem;
  }

  .lg\:gap-y-\[100px\] {
    row-gap: 100px;
  }

  .lg\:rounded-\[50px\] {
    border-radius: 50px;
  }

  .lg\:rounded-tl-\[50px\] {
    border-top-left-radius: 50px;
  }

  .lg\:rounded-tr-\[50px\] {
    border-top-right-radius: 50px;
  }

  .lg\:border-r {
    border-right-width: 1px;
  }

  .lg\:p-10 {
    padding: 2.5rem;
  }

  .lg\:p-\[30px\] {
    padding: 30px;
  }

  .lg\:p-\[50px\] {
    padding: 50px;
  }

  .lg\:px-\[30px\] {
    padding-left: 30px;
    padding-right: 30px;
  }

  .lg\:px-\[60px\] {
    padding-left: 60px;
    padding-right: 60px;
  }

  .lg\:px-\[73px\] {
    padding-left: 73px;
    padding-right: 73px;
  }

  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .lg\:py-\[100px\] {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .lg\:py-\[15px\] {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .lg\:py-\[50px\] {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .lg\:pb-20 {
    padding-bottom: 5rem;
  }

  .lg\:pb-\[100px\] {
    padding-bottom: 100px;
  }

  .lg\:pb-\[110px\] {
    padding-bottom: 110px;
  }

  .lg\:pl-20 {
    padding-left: 5rem;
  }

  .lg\:pr-20 {
    padding-right: 5rem;
  }

  .lg\:pr-\[70px\] {
    padding-right: 70px;
  }

  .lg\:pt-2 {
    padding-top: 0.5rem;
  }

  .lg\:pt-20 {
    padding-top: 5rem;
  }

  .lg\:pt-\[100px\] {
    padding-top: 100px;
  }

  .lg\:pt-\[150px\] {
    padding-top: 150px;
  }

  .lg\:pt-\[185px\] {
    padding-top: 185px;
  }

  .lg\:pt-\[190px\] {
    padding-top: 190px;
  }

  .lg\:pt-\[30px\] {
    padding-top: 30px;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .lg\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .lg\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .lg\:text-\[22px\] {
    font-size: 22px;
  }

  .lg\:text-\[26px\] {
    font-size: 26px;
  }

  .lg\:text-\[35px\] {
    font-size: 35px;
  }

  .lg\:text-\[56px\] {
    font-size: 56px;
  }

  .lg\:text-\[60px\] {
    font-size: 60px;
  }

  .lg\:text-\[64px\] {
    font-size: 64px;
  }

  .lg\:text-\[65px\] {
    font-size: 65px;
  }

  .lg\:text-\[70px\] {
    font-size: 70px;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .lg\:after\:block::after {
    content: var(--tw-content);
    display: block;
  }

  .lg\:after\:inline-block::after {
    content: var(--tw-content);
    display: inline-block;
  }

  .lg\:last\:border-none:last-child {
    border-style: none;
  }

  .lg\:last\:pb-0:last-child {
    padding-bottom: 0px;
  }

  .lg\:odd\:after\:block:nth-child(odd)::after {
    content: var(--tw-content);
    display: block;
  }
}

@media (min-width: 1200px) {
  @media (min-width: 992px) {
    .xl\:lg\:grid-cols-\[1fr_repeat\(4\2c _auto\)\] {
      grid-template-columns: 1fr repeat(4, auto);
    }
  }

  .xl\:absolute {
    position: absolute;
  }

  .xl\:-left-\[2\%\] {
    left: -2%;
  }

  .xl\:-right-\[200px\] {
    right: -200px;
  }

  .xl\:left-10 {
    left: 2.5rem;
  }

  .xl\:left-\[320px\] {
    left: 320px;
  }

  .xl\:left-\[550px\] {
    left: 550px;
  }

  .xl\:right-10 {
    right: 2.5rem;
  }

  .xl\:top-1\/2 {
    top: 50%;
  }

  .xl\:top-20 {
    top: 5rem;
  }

  .xl\:top-\[52\%\] {
    top: 52%;
  }

  .xl\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .xl\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .xl\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .xl\:-mt-20 {
    margin-top: -5rem;
  }

  .xl\:mb-20 {
    margin-bottom: 5rem;
  }

  .xl\:mb-7 {
    margin-bottom: 1.75rem;
  }

  .xl\:mb-\[100px\] {
    margin-bottom: 100px;
  }

  .xl\:mb-\[127px\] {
    margin-bottom: 127px;
  }

  .xl\:mb-\[50px\] {
    margin-bottom: 50px;
  }

  .xl\:mb-\[60px\] {
    margin-bottom: 60px;
  }

  .xl\:mt-20 {
    margin-top: 5rem;
  }

  .xl\:mt-\[100px\] {
    margin-top: 100px;
  }

  .xl\:mt-\[120px\] {
    margin-top: 120px;
  }

  .xl\:mt-\[50px\] {
    margin-top: 50px;
  }

  .xl\:mt-\[90px\] {
    margin-top: 90px;
  }

  .xl\:block {
    display: block;
  }

  .xl\:inline-block {
    display: inline-block;
  }

  .xl\:h-80 {
    height: 20rem;
  }

  .xl\:h-\[120px\] {
    height: 120px;
  }

  .xl\:h-\[50px\] {
    height: 50px;
  }

  .xl\:h-\[7px\] {
    height: 7px;
  }

  .xl\:w-20 {
    width: 5rem;
  }

  .xl\:w-\[120px\] {
    width: 120px;
  }

  .xl\:w-\[50px\] {
    width: 50px;
  }

  .xl\:w-\[7px\] {
    width: 7px;
  }

  .xl\:w-\[90px\] {
    width: 90px;
  }

  .xl\:w-auto {
    width: auto;
  }

  .xl\:max-w-3xl {
    max-width: 48rem;
  }

  .xl\:max-w-\[533px\] {
    max-width: 533px;
  }

  .xl\:max-w-\[636px\] {
    max-width: 636px;
  }

  .xl\:max-w-\[665px\] {
    max-width: 665px;
  }

  .xl\:max-w-\[80\%\] {
    max-width: 80%;
  }

  .xl\:max-w-\[856px\] {
    max-width: 856px;
  }

  .xl\:max-w-xl {
    max-width: 36rem;
  }

  .xl\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl\:grid-cols-\[0\.8fr_minmax\(0\2c _1fr\)\] {
    grid-template-columns: 0.8fr minmax(0, 1fr);
  }

  .xl\:grid-cols-\[0\.9fr_1fr\] {
    grid-template-columns: 0.9fr 1fr;
  }

  .xl\:grid-cols-\[0\.9fr_minmax\(0\2c _1fr\)\] {
    grid-template-columns: 0.9fr minmax(0, 1fr);
  }

  .xl\:grid-cols-\[1\.1fr_minmax\(0\2c _1fr\)\] {
    grid-template-columns: 1.1fr minmax(0, 1fr);
  }

  .xl\:grid-cols-\[1\.2fr_minmax\(0\2c _1fr\)\] {
    grid-template-columns: 1.2fr minmax(0, 1fr);
  }

  .xl\:grid-cols-\[1\.4fr_minmax\(0\2c _1fr\)\] {
    grid-template-columns: 1.4fr minmax(0, 1fr);
  }

  .xl\:grid-cols-\[1fr_minmax\(0\2c 0\.7fr\)\] {
    grid-template-columns: 1fr minmax(0, 0.7fr);
  }

  .xl\:grid-cols-\[1fr_minmax\(0\2c _0\.6fr\)\] {
    grid-template-columns: 1fr minmax(0, 0.6fr);
  }

  .xl\:grid-cols-\[1fr_minmax\(0\2c _0\.7fr\)\] {
    grid-template-columns: 1fr minmax(0, 0.7fr);
  }

  .xl\:grid-cols-\[1fr_minmax\(0\2c _0\.82fr\)\] {
    grid-template-columns: 1fr minmax(0, 0.82fr);
  }

  .xl\:grid-cols-\[1fr_minmax\(0\2c _0\.85fr\)\] {
    grid-template-columns: 1fr minmax(0, 0.85fr);
  }

  .xl\:grid-cols-\[1fr_minmax\(0\2c _0\.9fr\)\] {
    grid-template-columns: 1fr minmax(0, 0.9fr);
  }

  .xl\:grid-cols-\[1fr_minmax\(0\2c _1\.1fr\)\] {
    grid-template-columns: 1fr minmax(0, 1.1fr);
  }

  .xl\:grid-cols-\[1fr_minmax\(0\2c _1\.2fr\)\] {
    grid-template-columns: 1fr minmax(0, 1.2fr);
  }

  .xl\:flex-row {
    flex-direction: row;
  }

  .xl\:items-center {
    align-items: center;
  }

  .xl\:justify-start {
    justify-content: flex-start;
  }

  .xl\:justify-between {
    justify-content: space-between;
  }

  .xl\:gap-10 {
    gap: 2.5rem;
  }

  .xl\:gap-11 {
    gap: 2.75rem;
  }

  .xl\:gap-20 {
    gap: 5rem;
  }

  .xl\:gap-28 {
    gap: 7rem;
  }

  .xl\:gap-32 {
    gap: 8rem;
  }

  .xl\:gap-4 {
    gap: 1rem;
  }

  .xl\:gap-48 {
    gap: 12rem;
  }

  .xl\:gap-60 {
    gap: 15rem;
  }

  .xl\:gap-\[100px\] {
    gap: 100px;
  }

  .xl\:gap-\[104px\] {
    gap: 104px;
  }

  .xl\:gap-\[110px\] {
    gap: 110px;
  }

  .xl\:gap-\[122px\] {
    gap: 122px;
  }

  .xl\:gap-\[134px\] {
    gap: 134px;
  }

  .xl\:gap-\[135px\] {
    gap: 135px;
  }

  .xl\:gap-\[140px\] {
    gap: 140px;
  }

  .xl\:gap-\[143px\] {
    gap: 143px;
  }

  .xl\:gap-\[148px\] {
    gap: 148px;
  }

  .xl\:gap-\[155px\] {
    gap: 155px;
  }

  .xl\:gap-\[209px\] {
    gap: 209px;
  }

  .xl\:gap-\[30px\] {
    gap: 30px;
  }

  .xl\:gap-\[60px\] {
    gap: 60px;
  }

  .xl\:gap-\[87px\] {
    gap: 87px;
  }

  .xl\:gap-\[90px\] {
    gap: 90px;
  }

  .xl\:gap-x-20 {
    -moz-column-gap: 5rem;
    column-gap: 5rem;
  }

  .xl\:gap-x-24 {
    -moz-column-gap: 6rem;
    column-gap: 6rem;
  }

  .xl\:gap-x-40 {
    -moz-column-gap: 10rem;
    column-gap: 10rem;
  }

  .xl\:gap-x-44 {
    -moz-column-gap: 11rem;
    column-gap: 11rem;
  }

  .xl\:gap-x-\[100px\] {
    -moz-column-gap: 100px;
    column-gap: 100px;
  }

  .xl\:gap-x-\[110px\] {
    -moz-column-gap: 110px;
    column-gap: 110px;
  }

  .xl\:gap-x-\[94px\] {
    -moz-column-gap: 94px;
    column-gap: 94px;
  }

  .xl\:gap-y-20 {
    row-gap: 5rem;
  }

  .xl\:gap-y-\[120px\] {
    row-gap: 120px;
  }

  .xl\:overflow-visible {
    overflow: visible;
  }

  .xl\:rounded-\[50px\] {
    border-radius: 50px;
  }

  .xl\:rounded-bl-\[100px\] {
    border-bottom-left-radius: 100px;
  }

  .xl\:rounded-br-\[100px\] {
    border-bottom-right-radius: 100px;
  }

  .xl\:rounded-tl-\[100px\] {
    border-top-left-radius: 100px;
  }

  .xl\:rounded-tr-\[100px\] {
    border-top-right-radius: 100px;
  }

  .xl\:p-10 {
    padding: 2.5rem;
  }

  .xl\:p-\[50px\] {
    padding: 50px;
  }

  .xl\:px-\[18px\] {
    padding-left: 18px;
    padding-right: 18px;
  }

  .xl\:px-\[30px\] {
    padding-left: 30px;
    padding-right: 30px;
  }

  .xl\:py-\[100px\] {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .xl\:py-\[80px\] {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .xl\:pb-\[100px\] {
    padding-bottom: 100px;
  }

  .xl\:pl-32 {
    padding-left: 8rem;
  }

  .xl\:pr-\[100px\] {
    padding-right: 100px;
  }

  .xl\:pt-1 {
    padding-top: 0.25rem;
  }

  .xl\:pt-32 {
    padding-top: 8rem;
  }

  .xl\:pt-36 {
    padding-top: 9rem;
  }

  .xl\:pt-\[100px\] {
    padding-top: 100px;
  }

  .xl\:pt-\[120px\] {
    padding-top: 120px;
  }

  .xl\:pt-\[130px\] {
    padding-top: 130px;
  }

  .xl\:pt-\[240px\] {
    padding-top: 240px;
  }

  .xl\:text-left {
    text-align: left;
  }

  .xl\:text-center {
    text-align: center;
  }

  .xl\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .xl\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .xl\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .xl\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .xl\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .xl\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }

  .xl\:text-\[28px\] {
    font-size: 28px;
  }

  .xl\:text-\[34px\] {
    font-size: 34px;
  }

  .xl\:text-\[52px\] {
    font-size: 52px;
  }

  .xl\:text-\[56px\] {
    font-size: 56px;
  }

  .xl\:text-\[58px\] {
    font-size: 58px;
  }

  .xl\:text-\[65px\] {
    font-size: 65px;
  }

  .xl\:text-\[70px\] {
    font-size: 70px;
  }

  .xl\:text-\[72px\] {
    font-size: 72px;
  }

  .xl\:text-\[76px\] {
    font-size: 76px;
  }

  .xl\:text-\[78px\] {
    font-size: 78px;
  }

  .xl\:text-\[86px\] {
    font-size: 86px;
  }

  .xl\:text-\[90px\] {
    font-size: 90px;
  }

  .xl\:after\:block::after {
    content: var(--tw-content);
    display: block;
  }

  .xl\:after\:w-\[calc\(100\%\+_80px\)\]::after {
    content: var(--tw-content);
    width: calc(100% + 80px);
  }
}

@media (min-width: 1400px) {
  .xxl\:left-20 {
    left: 5rem;
  }

  .xxl\:left-\[44px\] {
    left: 44px;
  }

  .xxl\:left-\[6\%\] {
    left: 6%;
  }

  .xxl\:left-\[90px\] {
    left: 90px;
  }

  .xxl\:right-0 {
    right: 0px;
  }

  .xxl\:right-20 {
    right: 5rem;
  }

  .xxl\:right-\[90px\] {
    right: 90px;
  }

  .xxl\:top-\[100px\] {
    top: 100px;
  }

  .xxl\:top-\[49\%\] {
    top: 49%;
  }

  .xxl\:top-\[75\%\] {
    top: 75%;
  }

  .xxl\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .xxl\:mt-\[130px\] {
    margin-top: 130px;
  }

  .xxl\:block {
    display: block;
  }

  .xxl\:inline-block {
    display: inline-block;
  }

  .xxl\:h-14 {
    height: 3.5rem;
  }

  .xxl\:h-\[275px\] {
    height: 275px;
  }

  .xxl\:h-\[331px\] {
    height: 331px;
  }

  .xxl\:w-\[125px\] {
    width: 125px;
  }

  .xxl\:w-\[291px\] {
    width: 291px;
  }

  .xxl\:max-w-\[1076px\] {
    max-width: 1076px;
  }

  .xxl\:max-w-\[700px\] {
    max-width: 700px;
  }

  .xxl\:max-w-\[896px\] {
    max-width: 896px;
  }

  .xxl\:max-w-full {
    max-width: 100%;
  }

  .xxl\:grid-cols-\[0\.7fr_1fr\] {
    grid-template-columns: 0.7fr 1fr;
  }

  .xxl\:grid-cols-\[1\.1fr_minmax\(0\2c _1fr\)\] {
    grid-template-columns: 1.1fr minmax(0, 1fr);
  }

  .xxl\:grid-cols-\[1fr_minmax\(0\2c _0\.8fr\)\] {
    grid-template-columns: 1fr minmax(0, 0.8fr);
  }

  .xxl\:grid-cols-\[1fr_minmax\(0\2c _1\.1fr\)\] {
    grid-template-columns: 1fr minmax(0, 1.1fr);
  }

  .xxl\:grid-cols-\[minmax\(0\2c 0\.33fr\)_1fr\] {
    grid-template-columns: minmax(0, 0.33fr) 1fr;
  }

  .xxl\:grid-cols-\[minmax\(0\2c 0\.4fr\)_1fr\] {
    grid-template-columns: minmax(0, 0.4fr) 1fr;
  }

  .xxl\:justify-between {
    justify-content: space-between;
  }

  .xxl\:gap-20 {
    gap: 5rem;
  }

  .xxl\:gap-6 {
    gap: 1.5rem;
  }

  .xxl\:gap-\[100px\] {
    gap: 100px;
  }

  .xxl\:gap-\[134px\] {
    gap: 134px;
  }

  .xxl\:gap-\[153px\] {
    gap: 153px;
  }

  .xxl\:gap-\[180px\] {
    gap: 180px;
  }

  .xxl\:gap-\[182px\] {
    gap: 182px;
  }

  .xxl\:gap-\[210px\] {
    gap: 210px;
  }

  .xxl\:gap-\[234px\] {
    gap: 234px;
  }

  .xxl\:gap-\[244px\] {
    gap: 244px;
  }

  .xxl\:gap-x-20 {
    -moz-column-gap: 5rem;
    column-gap: 5rem;
  }

  .xxl\:gap-x-32 {
    -moz-column-gap: 8rem;
    column-gap: 8rem;
  }

  .xxl\:gap-x-56 {
    -moz-column-gap: 14rem;
    column-gap: 14rem;
  }

  .xxl\:gap-x-\[104px\] {
    -moz-column-gap: 104px;
    column-gap: 104px;
  }

  .xxl\:gap-x-\[134px\] {
    -moz-column-gap: 134px;
    column-gap: 134px;
  }

  .xxl\:gap-x-\[146px\] {
    -moz-column-gap: 146px;
    column-gap: 146px;
  }

  .xxl\:gap-x-\[185px\] {
    -moz-column-gap: 185px;
    column-gap: 185px;
  }

  .xxl\:gap-x-\[220px\] {
    -moz-column-gap: 220px;
    column-gap: 220px;
  }

  .xxl\:gap-y-\[60px\] {
    row-gap: 60px;
  }

  .xxl\:pb-\[120px\] {
    padding-bottom: 120px;
  }

  .xxl\:pb-\[177px\] {
    padding-bottom: 177px;
  }

  .xxl\:pt-\[185px\] {
    padding-top: 185px;
  }

  .xxl\:pt-\[200px\] {
    padding-top: 200px;
  }

  .xxl\:text-left {
    text-align: left;
  }

  .xxl\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .xxl\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .xxl\:text-\[100px\] {
    font-size: 100px;
  }

  .xxl\:text-\[110px\] {
    font-size: 110px;
  }

  .xxl\:text-\[120px\] {
    font-size: 120px;
  }

  .xxl\:text-\[26px\] {
    font-size: 26px;
  }

  .xxl\:text-\[28px\] {
    font-size: 28px;
  }

  .xxl\:text-\[35px\] {
    font-size: 35px;
  }

  .xxl\:text-\[58px\] {
    font-size: 58px;
  }

  .xxl\:text-\[60px\] {
    font-size: 60px;
  }

  .xxl\:text-\[80px\] {
    font-size: 80px;
  }

  .xxl\:text-\[90px\] {
    font-size: 90px;
  }
}






.d-flex-center{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.toggle_dot_bg{
  display: flex;
  align-items: center;
  height: 35px;
  width: 62px;
  position: relative;
  background-color: #121212;
  border-radius: 40px;
}
.toggle_dot{
  background-color: var(--primary-color);
  border-radius: 50%;
  width: 28px;
  height: 28px;
  margin: 3px;
}

.d-none{
  display: none !important; 
}
.d-flex-y-center{
  display: flex !important;
  justify-content: center !important;
}
.d-flex-x-center{
  display: flex !important;
  align-items: center !important;
}
.d-flex-center{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.w-100{
  width: 100% !important;
}