@font-face {
    font-family: 'Public Sans';
    src: url('PublicSans-Black.woff2') format('woff2'),
        url('PublicSans-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Public Sans';
    src: url('PublicSans-BlackItalic.woff2') format('woff2'),
        url('PublicSans-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Public Sans';
    src: url('PublicSans-Bold.woff2') format('woff2'),
        url('PublicSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Public Sans';
    src: url('PublicSans-ExtraBold.woff2') format('woff2'),
        url('PublicSans-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Public Sans';
    src: url('PublicSans-BoldItalic.woff2') format('woff2'),
        url('PublicSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Public Sans';
    src: url('PublicSans-ExtraLight.woff2') format('woff2'),
        url('PublicSans-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Public Sans';
    src: url('PublicSans-ExtraBoldItalic.woff2') format('woff2'),
        url('PublicSans-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Public Sans';
    src: url('PublicSans-ExtraLightItalic.woff2') format('woff2'),
        url('PublicSans-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Public Sans';
    src: url('PublicSans-Italic.woff2') format('woff2'),
        url('PublicSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Public Sans';
    src: url('PublicSans-LightItalic.woff2') format('woff2'),
        url('PublicSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Public Sans';
    src: url('PublicSans-Light.woff2') format('woff2'),
        url('PublicSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Public Sans';
    src: url('PublicSans-Medium.woff2') format('woff2'),
        url('PublicSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Public Sans';
    src: url('PublicSans-Regular.woff2') format('woff2'),
        url('PublicSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Public Sans';
    src: url('PublicSans-MediumItalic.woff2') format('woff2'),
        url('PublicSans-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Public Sans';
    src: url('PublicSans-Thin.woff2') format('woff2'),
        url('PublicSans-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Public Sans';
    src: url('PublicSans-SemiBold.woff2') format('woff2'),
        url('PublicSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Public Sans';
    src: url('PublicSans-SemiBoldItalic.woff2') format('woff2'),
        url('PublicSans-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Public Sans';
    src: url('PublicSans-ThinItalic.woff2') format('woff2'),
        url('PublicSans-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

